import { makeStyles, Theme, createStyles } from '@material-ui/core';

const caseStyle = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'relative',
      padding: 12,
      textAlign: 'left',
      cursor: 'pointer',
      color: theme.palette.text.secondary,
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
        marginLeft: 0
      },
      border: `1px solid rgba(170, 170, 170, 0.4)`
    },
    alignLeft: {
      textAlign: 'left'
    },
    paperLeft: {
      position: 'relative',
      padding: 12,
      textAlign: 'left',
      cursor: 'pointer',
      color: theme.palette.text.secondary,
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        marginRight: 20
      },
      border: `1px solid rgba(170, 170, 170, 0.4)`
    },
    paperRight: {
      position: 'relative',
      padding: 12,
      textAlign: 'left',
      cursor: 'pointer',
      color: theme.palette.text.secondary,
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        marginLeft: 20
      },
      border: `1px solid rgba(170, 170, 170, 0.4)`
    },

    casesBox: {
      marginTop: 25
    },
    hospitalName: {
      fontSize: 16
    },
    imageBox: {
      margin: '50% auto 0px'
    },
    noWrapWidth: {
      width: 200
    },
    emptyCases: {
      margin: '16px auto 0px',
      textAlign: 'center'
    },
    emptyCasesHint: {
      margon: '0px auto 0px',
      textAlign: 'center'
    },
    mb4: {
      marginBottom: 4
    },
    icon: {
      marginRight: 4
    },
    avatar: {
      width: 60,
      height: 60,
      padding: '21px 5px',
      // display: 'flex',
      // flexDirection: 'row',
      // justifyContent: 'center',
      // alignItems: 'center',
      borderRadius: '50%',
      backgroundColor: '#f7b500',
      // fontSize: 20,
      color: '#ffff',
      textAlign: 'center'
    },
    caseCard: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 10
    },
    selectedButton: {
      marginLeft: 'auto'
    },
    checkbox: {
      position: 'absolute',
      top: 10,
      right: 10
    },
    rightDiv: {
      position: 'fixed',
      top: '35%',
      left: 0,
      width: '100%',
      height: '100%'
    }
  })
);

export default caseStyle;
