import React, { useCallback, useEffect, useRef } from 'react';
import {
  Box,
  Grid,
  Button,
  Typography,
  CircularProgress,
  InputAdornment,
  Input,
  Select,
  FormControl,
  InputLabel,
  Tabs,
  Tab,
  AppBar,
  Paper,
  ClickAwayListener,
  Snackbar
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import GetAppIcon from '@material-ui/icons/GetApp';
import * as api from '../../../services/apiService';
import { getUser } from '../../../utils';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import Alert from '@material-ui/lab/Alert';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import ClearIcon from '@material-ui/icons/Clear';
import article from '../../../assets/article.svg';
import calculate from '../../../assets/calculate.svg';
import locker from '../../../assets/locker.svg';
import history from '../../../utils/history';
import dashboardStyle from './Styles';
import TablePagination from '@material-ui/core/TablePagination';
import TabPanel from '../../../components/TabPanel/TabPanel';
import Case from '../../../components/Case/Case';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import ImportCase from '../../../components/ImportCase/ImportCase';
import DescriptionIcon from '@material-ui/icons/Description';
import FolderIcon from '@material-ui/icons/Folder';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DeleteDialog from '../../../components/Dialogs/DeleteDialog';
import RegistryDialog from '../../../components/Dialogs/RegistryDialog';
import UpgradeDialog from '../../../components/Dialogs/UpgradeDialog';
import { useParams } from 'react-router';
import { doctorParams, userInfoType } from '../../../types';
import { NavLink, useLocation } from 'react-router-dom';
import { RingLoader } from 'react-spinners';
import CalendarWithNumbers from '../../../components/CalendarWithNumbers/CalendarWithNumbers';

const options = ['ALL', 'UPCOMING', 'WEEK', 'MONTH'];
const optionsReg = ['ALL', 'WEEK', 'MONTH'];
const Dashboard = (props) => {
  const {
    setShowEditCaseMessage,
    caseMessage,
    showEditCaseMessage,
    registryId,
    executed,
    title,
    setUserAccess
  } = props;
  const location = useLocation().pathname;
  const classes = dashboardStyle();
  const [cases, setCases] = React.useState<any[]>([]);
  const [page, setPage] = React.useState<number>(0);
  const [isOpen, setisOpen] = React.useState<boolean>(false);
  const [count, setCount] = React.useState<number>(1);
  const [endCases, setEndCasess] = React.useState<boolean>(false);
  const [isBottom, setIsBottom] = React.useState<boolean>(true);
  const [caseIndicator, setCaseIndicator] = React.useState<boolean>(false);
  const [filter, setFilter] = React.useState<string>('INFO');
  const [showImportCaseMsgReg, setShowImportCaseMsgReg] =
    React.useState<boolean>(false);
  const [importCaseMsgReg, setImportCaseMsgReg] = React.useState<string[]>([]);
  const [showImportCaseMsg, setShowImportCaseMsg] =
    React.useState<boolean>(false);
  const [importCaseMsg, setImportCaseMsg] = React.useState<string[]>([]);
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');
  const [canAddCases, setCanAddCases] = React.useState<boolean>(false);
  const [openExportCase, setOpenExportCase] = React.useState<boolean>(false);
  const [openLoader, setOpenLoader] = React.useState<boolean>(false);
  const [openDetails, setOpenDeatils] = React.useState<boolean>(false);
  const [openExportCaseDialog, setOpenExportCaseDialog] =
    React.useState<boolean>(false);
  const [tokenError, setTokenError] = React.useState<boolean>(false);
  const [messageError, setMessageError] = React.useState<string>('');
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [showExportError, setShowExportError] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string[]>([]);
  const [caseId, setCaseId] = React.useState();
  const [caseIndex, setCaseIndex] = React.useState<number>(-1);
  const [performSearch, setPerformSearch] = React.useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);
  const [isImported, setIsImported] = React.useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const [optionForView, setOptionForView] = React.useState('Patient Info');
  const [pageLimit, setPageLimit] = React.useState(12);
  const [showMultiSelectTab, setShowMultiSelectTab] =
    React.useState<boolean>(false);
  const [showError, setShowError] = React.useState<boolean>(false);
  const [currentPlan, setCurrentPlan] = React.useState<any>();
  const [newPlan, setNewPlan] = React.useState<boolean>(false);
  const [filterCases, setfilterCases] = React.useState([]);
  const [dataNumber, setDataNumber] = React.useState<any>();
  const [openImportCase, setOpenImportCase] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userToken, setUserToken] = React.useState<string>('');
  const openFilter = Boolean(anchorEl);
  const [openMenu, setOpenMenu] = React.useState<boolean>(false);
  const { doctorId } = useParams<doctorParams>();
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [requestedReimbursement, setRequestedReimbursement] = React.useState<boolean>(false);
  const [planId, setPlanId] = React.useState<number | undefined>();
  const user = getUser();
  const userId = getUser() ? getUser().id : null;
  const [userInfo, setUserInfo] = React.useState<userInfoType>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getUserInfo = useCallback(async () => {
    try {
      const { data, statusCode } = await api.getUserInfo({
        toCamel: true,
        userId
      });
      if (statusCode === api.status.SUCCESS && isMounted.current) {
        setPlanId(data.subscription.planId);
        setUserInfo({ ...data });
        setIsLoading(false);
        setRequestedReimbursement(true);
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  }, [userId]);


  useEffect(() => {
    if (!userInfo?.id) {
      getUserInfo();
    }
  }, [userInfo]);

  const clearSearch = () => {
    setSearchKeyword('');
    setPage(0);
    doSearch();
  };
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if (searchKeyword === '') {
        clearSearch();
      }
    }

    return () => {
      unmounted = true;
    };
  }, [searchKeyword]);

  const addCheckAtt = (cases) => {
    let newCases = cases && cases.map((obj) => ({ ...obj, isChecked: false }));
    const result = newCases.map((el) =>
      el.hospital != null
        ? {
          ...el,
          institute: {
            id: el.hospital.id,
            label: el.hospital.label
          }
        }
        : el
    );
    return result;
  };

  const getCaseId = () => {
    let filterCaseId = [];
    for (let i = 0; i < filterCases.length; ++i) {
      filterCaseId.push(filterCases[i]['id']);
    }
    return filterCaseId.join();
  };

  const getCaseIdForExport = () => {
    let filterCaseId = [];
    for (let i = 0; i < filterCases.length; ++i) {
      filterCaseId.push(filterCases[i]['id']);
    }
    return filterCaseId;
  };

  const getCaseRegistery = (data) => {
    let procedures = data.procedures;
    let newData: any = [];
    for (let i = 0; i < procedures.length; ++i) {
      newData.push(procedures[i].data);
      newData[i] = {
        ...newData[i],
        id: procedures[i].id
      };
    }
    return newData;
  };
  const removeSelect = () => {
    let newCases = cases.map((obj) => ({ ...obj, isChecked: false }));
    setCases(newCases);
  };
  const handleCloseIndicator = () => {
    setCaseIndicator(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const updateCases = React.useCallback(
    async (newCases: any) => {
      if (page === 0) {
        setCases(addCheckAtt(newCases));
      } else {
        setCases(addCheckAtt(newCases));
      }
    },
    [page]
  );
  const getRegistryCases = React.useCallback(
    async (filter, searchBy, value, pageLimit, page) => {
      setOpenLoader(true);
      if (!endCases || page === 0) {
        const { data, status_code: statusCode } = await api.searchRegistryCases(
          registryId,
          page,
          pageLimit,
          {
            filter: filter,
            search_by: searchBy,
            value: value
          }
        );
        if (statusCode === api.status.SUCCESS) {
          setCount(Math.round(data.procedures_count));
          if (data.procedures.length < pageLimit) {
            setEndCasess(true);
          }

          updateCases(getCaseRegistery(data));
        } else {
          handleLoaderClose();
        }
        setIsBottom(false);
        setPerformSearch(false);
      }
      handleLoaderClose();
    },
    [
      endCases,
      filter,
      page,
      searchKeyword,
      selectedIndex,
      updateCases,
      user?.id
    ]
  );
  const getCases = useCallback(
    async (filter, searchBy, value, pageLimit, page) => {
      if (title === 'dashboard') {
        setOpenLoader(true);

        if (!endCases || page === 0) {
          try {
            const { data, status_code: statusCode } = await api.searchCases(
              user?.id,
              page,
              pageLimit,
              {
                filter: filter,
                search_by: searchBy,
                value: value
              }
            );
            if (statusCode === api.status.SUCCESS && isMounted.current) {
              setCount(Math.round(data.procedures_count));
              if (data.procedures.length < pageLimit) {
                setEndCasess(true);
              }
              updateCases(data.procedures);
            }
          } catch (error) {
            console.error('Error fetching cases:', error);
          } finally {
            if (isMounted.current) {
              setIsBottom(false);
              setPerformSearch(false);
              handleLoaderClose();
            }
          }
        }
      }
    },
    [
      endCases,
      filter,
      page,
      searchKeyword,
      selectedIndex,
      updateCases,
      user?.id,
      title
    ]
  );

  const getSharedCases = React.useCallback(
    async (filter, searchBy, value, pageLimit, page) => {
      if (title === 'sharedDashboard') {
        setOpenLoader(true);

        if (!endCases || page === 0) {
          const { data, status_code: statusCode } = await api.searchCases(
            doctorId,
            page,
            pageLimit,
            {
              filter: filter,
              search_by: searchBy,
              value: value
            }
          );
          if (statusCode === api.status.SUCCESS) {
            setUserAccess(data.permission);
            if (data.permission !== 'VIEWER') {
              setCanAddCases(true);
            }

            setCount(Math.round(data.procedures_count));
            if (data.procedures.length < pageLimit) {
              setEndCasess(true);
            }

            updateCases(data.procedures);
          }
          setIsBottom(false);
          setPerformSearch(false);
        }
        handleLoaderClose();
      }
    },
    [
      endCases,
      filter,
      page,
      searchKeyword,
      selectedIndex,
      updateCases,
      doctorId,
      title
    ]
  );

  const getDashboardNumber = useCallback(async () => {
    try {
      const { data, status_code: statusCode } = await api.getDashboardNumber();
      if (statusCode === api.status.SUCCESS && isMounted.current) {
        setDataNumber(data);
      }
    } catch (error) {
      console.error('Error fetching dashboard number:', error);
    }
  }, []);

  const getSharedDashboardNumber = React.useCallback(async (doctorId) => {
    const { data, status_code: statusCode } =
      await api.getSharedDashboardNumber(doctorId);
    if (statusCode === api.status.SUCCESS) {
      setDataNumber(data);
    }
  }, []);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if (title === 'dashboard') {
        getDashboardNumber();
      } else if (title === 'sharedDashboard') {
        getSharedDashboardNumber(doctorId);
      }
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if (title === 'dashboard') {
        getCases(
          options[selectedIndex],
          filter,
          searchKeyword,
          pageLimit,
          page
        );
      } else if (title === 'Registry') {
        getRegistryCases(
          optionsReg[selectedIndex],
          filter,
          searchKeyword,
          pageLimit,
          page
        );
      } else if (title === 'sharedDashboard') {
        getSharedCases(
          options[selectedIndex],
          filter,
          searchKeyword,
          pageLimit,
          page
        );
      }
    }

    return () => {
      unmounted = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, isImported, pageLimit, filter]);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if (isBottom) {
        if (title === 'dashboard') {
          getCases(
            options[selectedIndex],
            filter,
            searchKeyword,
            pageLimit,
            page
          );
        } else if (title === 'Registry') {
          getRegistryCases(
            options[selectedIndex],
            filter,
            searchKeyword,
            pageLimit,
            page
          );
        } else if (title === 'sharedDashboard') {
          getSharedCases(
            options[selectedIndex],
            filter,
            searchKeyword,
            pageLimit,
            page
          );
        }
      }
    }

    return () => {
      unmounted = true;
    };
  }, [isBottom]);

  const deleteProcedureById = async (caseId: any) => {
    if (caseId) {
      setOpenLoader(true);
      const { status_code: statusCode, data } = await api.deleteProcedureById(
        user?.id,
        caseId
      );

      if (statusCode === api.status.SUCCESS) {
        let updatedCase;

        if (caseId.length === 1) {
          updatedCase = cases.filter((item) => item.id !== caseId);
        } else {
          updatedCase = cases.filter((item) => !caseId.includes(item.id));
        }

        setCases(updatedCase);
        handleDetailsClose();
        setMessage(['success', 'Procedure deleted successfully!']);
        setShowMessage(true);
        setTimeout(() => setShowMessage(false), 1000);
      } else {
        setMessage(['error', `${data}`]);
        setShowMessage(true);
        setTimeout(() => setShowMessage(false), 1000);
      }
      handleLoaderClose();
    }
  };

  const deleteRegistryProcedureById = async (caseId: any, registryId) => {
    if (caseId && registryId) {
      setOpenLoader(true);
      const { status_code: statusCode, data } =
        await api.deleteRegistryProcedureById(registryId, caseId);

      if (statusCode === api.status.SUCCESS) {
        let updatedCase;

        if (caseId.length === 1) {
          updatedCase = cases.filter((item) => item.id !== caseId);
        } else {
          updatedCase = cases.filter((item) => !caseId.includes(item.id));
        }

        setCases(updatedCase);
        handleDetailsClose();
        setMessage(['success', 'Procedure deleted successfully!']);
        setShowMessage(true);
        setTimeout(() => setShowMessage(false), 1000);
      } else {
        setMessage(['error', `${data}`]);
        setShowMessage(true);
        setTimeout(() => setShowMessage(false), 1000);
      }
      handleLoaderClose();
    }
  };

  const handleSearchChange = (event: any) => {
    setSearchKeyword(event.target.value);
  };

  const doSearch = async () => {
    setEndCasess(false);
    setPage(0);
    setIsBottom(true);
    setPerformSearch(true);
  };

  const filterChange = (filter: any) => {
    setFilter(filter);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageLimit(parseInt(event.target.value, 10));
    setPage(0);
    setEndCasess(false);
  };

  const handleChangeIndex = (event, newValue) => {
    setEndCasess(false);

    setSelectedIndex(newValue);
    if (title === 'dashboard') {
      getCases(options[newValue], filter, searchKeyword, pageLimit, page);
    } else if (title === 'Registry') {
      getRegistryCases(
        optionsReg[newValue],
        filter,
        searchKeyword,
        pageLimit,
        page
      );
    } else if (title === 'sharedDashboard') {
      getSharedCases(options[newValue], filter, searchKeyword, pageLimit, page);
    }
  };

  const excelExport = () => {
    let casesId: any[] = [];
    for (let i = 0; i < filterCases.length; i++) {
      casesId.push(filterCases[i]['id']);
    }
    if (casesId.length !== 0) {
      if (registryId) {
        excelExportRegistryCase(registryId, caseId);
      } else {
        excelExportCase(casesId);
      }
    }
  };

  const pdfExport = () => {
    let casesId: any[] = [];
    for (let i = 0; i < filterCases.length; i++) {
      casesId.push(filterCases[i]['id']);
    }
    if (casesId.length !== 0) {
      if (registryId) {
        pdfExportRegistryCase(registryId, casesId[0]);
      } else {
        pdfExportCase(casesId);
      }
    }
  };

  const excelExportCase = async (casesId) => {
    setOpenLoader(true);
    const { data, status_code: statusCode } = await api.exportExcelCase(
      user?.id,
      {
        type: 'EXCEL',
        procedure_ids: casesId
      }
    );
    if (statusCode === api.status.SUCCESS) {
      setMessage([
        'success',
        'Exporting now. Once done, you will find the files in your Profile under Exported Procedures.'
      ]);
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 3000);
    } else {
      setMessage(['error', `${data}`]);
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 1000);
    }
    if (statusCode === api.status.SUCCESS && data.path) {
    }
    handleExportClose();
    handleDetailsClose();
    handleLoaderClose();
  };
  const excelExportRegistryCase = async (registryId, casesId) => {
    setOpenLoader(true);
    const { data, status_code: statusCode } = await api.exportRegistryExcelCase(
      registryId,
      {
        procedure_ids: casesId
      }
    );
    if (statusCode === api.status.SUCCESS) {
      setMessage([
        'success',
        'Exporting now. Once done, you will find the files in your Profile under Exported Procedures.'
      ]);
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 3000);
    } else {
      setMessage(['error', `${data}`]);
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 1000);
    }
    if (statusCode === api.status.SUCCESS && data.path) {
    }
    handleExportClose();
    handleDetailsClose();
    handleLoaderClose();
  };

  const pdfExportRegistryCase = async (registryId, casesId) => {
    setOpenLoader(true);
    const { data, status_code: statusCode } = await api.exportRegistryPDFCase(
      registryId,
      {
        procedure_ids: casesId
      }
    );
    if (statusCode === api.status.SUCCESS) {
      setMessage([
        'success',
        'Exporting now. Once done, you will find the files in your Profile under Exported Procedures.'
      ]);
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 3000);
    }
    if (statusCode === api.status.SUCCESS && data.path) {
      const link = document.createElement('a');
      link.href = data.path;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    handleExportClose();
    handleDetailsClose();
    handleLoaderClose();
  };

  const pdfExportCase = async (caseId) => {
    setOpenLoader(true);
    const { data, status_code: statusCode } = await api.exportPDFCase(
      user?.id,
      {
        type: 'PDF',
        procedure_ids: caseId
      }
    );
    if (statusCode === api.status.SUCCESS) {
      setMessage([
        'success',
        'Exporting now. Once done, you will find the files in your Profile under Exported Procedures.'
      ]);
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 3000);
    }
    if (statusCode === api.status.SUCCESS && data.path) {
      const link = document.createElement('a');
      link.href = data.path;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    handleExportClose();
    handleDetailsClose();
    handleLoaderClose();
  };

  const handleExportClose = () => {
    setOpenExportCase(false);
  };

  const handleLoaderClose = () => {
    setOpenLoader(false);
  };

  const handleDetailsClose = () => {
    setOpenDeatils(false);
  };

  const openCaseDetails = (id: any, index: any) => {
    setCaseId(id);
    setCaseIndex(index);
    if (title === 'dashboard') {
      history.push('/case/' + id);
    } else if (title === 'Registry') {
      history.push('/registrycase/' + registryId + '/' + id);
    } else if (title === 'sharedDashboard') {
      history.push('/shared-case/' + doctorId + '/' + id);
    }
  };

  const handleMenuClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpenMenu(false);
  };

  const addCase = () => {
    if (registryId) {
      history.push('/new-Case-registry/' + registryId);
    } else {
      if (location.includes('/shared-dashboard/')) {
        history.push('/new-shared-case/' + doctorId);
      } else {
        history.push('/new-case');
      }
    }
  };

  const onDelete = () => {
    handleCloseConfirm();
    let casesId = getCaseId();
    if (filterCases.length > 0 && registryId) {
      deleteRegistryProcedureById(casesId, registryId);
    } else {
      deleteProcedureById(casesId);
    }
  };
  const handlePageChange = (event: any, value: number) => {
    if (page > value) {
      setEndCasess(false);
    }
    setPage(value);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`
    };
  }
  const getSeverity = (color: any) => {
    return color;
  };

  const handleCloseDialog = () => {
    setisOpen(false);
  };

  const goToRegistry = (id) => {
    const newId = parseInt(id, 10);
    history.push('/registry/' + newId);
  };
  const handleChange = (event) => {
    setOptionForView(event.target.value);
    if (event.target.value === 'Patient Info') {
      filterChange('INFO');
    } else if (event.target.value === 'Institution') {
      filterChange('INSTITUTION');
    } else if (event.target.value === 'CPT') {
      filterChange('CPT');
    } else if (event.target.value === 'Procedure') {
      filterChange('PROCEDURE');
    }
  };
  const getCurrentPlan = React.useCallback(async () => {
    const { data, status_code: statusCode } = await api.getCurrentPlan();
    if (statusCode === api.status.SUCCESS) {
      setCurrentPlan(data);
    }
  }, []);

  const getUserToken = useCallback(async () => {
    try {
      const { data, status_code: statusCode } = await api.getUserToken();
      if (isMounted.current) {
        setNewPlan(false);
        if (statusCode === api.status.SUCCESS) {
          setUserToken(data.token);
        } else if (statusCode === api.status.ERROR_UPDATE) {
          setTokenError(true);
          setMessageError(data);
        }
      }
    } catch (error) {
      console.error('Error fetching user token:', error);
    }
  }, []);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      getUserToken();
      if (isOpen) {
        getCurrentPlan();
      }
    }

    return () => {
      unmounted = true;
    };
  }, [isOpen]);

  const getEstimatedReimbursement = () => {
    let total = 0;
    if (requestedReimbursement && userInfo?.reimbursement) {
      if (userInfo.reimbursement.reimbursementModel == 'SALARY_BASED') {
        return 'Salary Based';
      } else if (userInfo.reimbursement.reimbursementModel == 'RVUS_BASED') {
        return dataNumber?.total_reimbursable * userInfo.reimbursement.rvusMultiplier;
      } else if (userInfo.reimbursement.reimbursementModel == "MIXED") {
        if (userInfo.reimbursement.threshold == "DOLLARS") {
          total = (dataNumber?.total_reimbursable * userInfo.reimbursement.rvusMultiplier) - userInfo.reimbursement.amount;
        } else if (userInfo.reimbursement.threshold == 'RVUS') {
          total = (dataNumber?.total_reimbursable - userInfo.reimbursement.amount) * userInfo.reimbursement.rvusMultiplier;
        }
        return total;
      }
    } else if (requestedReimbursement && !userInfo?.reimbursement) {
      return 0;
    }
  }

  const estimatedReimbursement = getEstimatedReimbursement();

  const getFinalEstimatedNumber = () => {
    const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
    if (typeof estimatedReimbursement === 'number') {
      if (estimatedReimbursement < 0) {
        return '-$' + (estimatedReimbursement * -1).toLocaleString('en-US', options);
      } else if (estimatedReimbursement > 0) {
        return '$' + estimatedReimbursement.toLocaleString('en-US', options);
      } else {
        return '$0.00';
      }
    }
    return estimatedReimbursement;
  }

  const getEstimatedNumberStyle = () => {
    if (typeof estimatedReimbursement === 'number') {
      return (estimatedReimbursement === 0 || userInfo?.reimbursement?.reimbursementModel == 'RVUS_BASED') && estimatedReimbursement < 100000 ? classes.number : classes.boxNumber;
    }
    return classes.text;
  }

  return (
    <div
      className={
        title === 'dashboard' || title === 'sharedDashboard'
          ? classes.root
          : classes.registryRoot
      }
    >
      <div>
        <Snackbar open={showError} color="warning">
          <Alert onClose={() => setShowError(false)} severity="warning">
            You can't manage your plan since you have cancelled subscription
          </Alert>
        </Snackbar>
        <Dialog open={caseIndicator} onClose={handleCloseIndicator}>
          <CircularProgress className={classes.indicator} />
        </Dialog>
        <UpgradeDialog
          isOpen={isOpen && !tokenError}
          onClose={() => setisOpen(false)}
          handleChange={handleChange}
          handleCloseDialog={handleCloseDialog}
          userToken={userToken}
          setNewPlan={setNewPlan}
          subscriptionId={currentPlan?.subscr_id}
        />
        <Snackbar open={showEditCaseMessage}>
          <Alert
            onClose={() => setShowEditCaseMessage(false)}
            color={caseMessage[0]}
            severity={caseMessage[0]}
          >
            {caseMessage[1]}
          </Alert>
        </Snackbar>{' '}
        <Snackbar
          open={showImportCaseMsgReg}
          className={classes.registrySnackbar}
        >
          <Alert
            onClose={() => setShowImportCaseMsgReg(false)}
            color={getSeverity(importCaseMsgReg[0])}
            severity={getSeverity(importCaseMsgReg[0])}
            action={
              importCaseMsgReg[0] === 'error' &&
                importCaseMsgReg[1] === 'You need to upgrade your plan' ? (
                <Button
                  onClick={() => {
                    if (!tokenError) {
                      setisOpen(true);
                      setShowImportCaseMsgReg(false);
                    } else {
                      setShowError(true);
                      setShowImportCaseMsgReg(false);
                    }
                  }}
                >
                  Manage plans
                </Button>
              ) : null
            }
          >
            <Box>
              {importCaseMsgReg[1]}{' '}
              {importCaseMsgReg[1] !== 'You need to upgrade your plan' ? (
                <Typography
                  className={[classes.inline, classes.cursor].join(' ')}
                  variant="h2"
                  onClick={() => goToRegistry(importCaseMsgReg[2])}
                >
                  Open registry
                </Typography>
              ) : null}
            </Box>
          </Alert>
        </Snackbar>{' '}
        <Snackbar open={showImportCaseMsg}>
          <Alert
            action={
              importCaseMsg[0] === 'error' &&
                importCaseMsg[1] === 'You need to upgrade your plan' ? (
                <Button
                  onClick={() => {
                    if (!tokenError) {
                      setisOpen(true);
                      setOpenImportCase(false);
                    } else {
                      setShowError(true);
                      setShowImportCaseMsg(false);
                    }
                  }}
                >
                  Manage plans
                </Button>
              ) : null
            }
            onClose={() => setShowImportCaseMsg(false)}
            color={getSeverity(importCaseMsg[0])}
            severity={getSeverity(importCaseMsg[0])}
          >
            {importCaseMsg[1]}
          </Alert>
        </Snackbar>{' '}
        <Snackbar open={showMessage}>
          <Alert
            onClose={() => setShowMessage(false)}
            color={getSeverity(message[0])}
          >
            {message[1]}
          </Alert>
        </Snackbar>{' '}
        <DeleteDialog
          isOpen={openConfirm}
          title={filterCases.length > 1 ? 'Delete Cases' : 'Delete Case'}
          firstOption={'Delete'}
          secondOption={'Cancel'}
          handleOptionOne={onDelete}
          handleOptionTwo={handleCloseConfirm}
          onClose={handleCloseConfirm}
          height={198}
          width={507}
        >
          {filterCases.length > 1
            ? 'Are you sure you want to delete these cases ?'
            : 'Are you sure you want to delete this case?'}
        </DeleteDialog>
      </div>
      <Box mx={title === 'dashboard' ? 2 : 0}>
        <Snackbar open={showExportError}>
          <Alert
            onClose={() => setShowExportError(false)}
            severity="error"
            color="error"
          >
            You do not have procedure match your criteria to be exported!
          </Alert>
        </Snackbar>{' '}
        {isWidthDown('lg', props.width) && title === 'dashboard' ? (
          <Box>
            <Typography variant="subtitle2" className={classes.title}>
              Dashboard
            </Typography>
          </Box>
        ) : null}
        <Grid item={true} container spacing={0} xs={12}>
          {title === 'dashboard' ? (
            <Grid
              item={true}
              container
              spacing={0}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.numberContainer}
            >
              <Grid
                item={true}
                xs={12}
                sm={6}
                md={6}
                lg={3}
                xl={3}
                className={classes.item}
              >
                <Paper className={classes.paperOfNum}>
                  {!isLoading ? (<Box className={classes.headerContainer}>
                    <Box className={classes.imgContainer}>
                      <img
                        src={article}
                        alt="article"
                        className={classes.avatarPrimary}
                      />
                    </Box>
                    <Typography variant="subtitle1" className={classes.number}>
                      {dataNumber?.procedures_count}
                    </Typography>
                  </Box>) : (
                    <Box className={classes.loadingBox}>
                      <RingLoader size={40} color={'#073B70'} loading={isLoading} />
                    </Box>
                  )}
                  <Box>
                    <Typography variant="h3" className={classes.description}>
                      Total Number of Cases
                    </Typography>
                  </Box>
                </Paper>
              </Grid>

              <Grid
                item={true}
                xs={12}
                sm={6}
                md={6}
                lg={3}
                xl={3}
                className={classes.item}
              >
                <Paper className={classes.paperOfNum}>
                  {!isLoading ? (<Box className={classes.headerContainer}>
                    <Box className={classes.imgContainer}>
                      <img
                        src={article}
                        alt="article"
                        className={classes.avatarPrimary}
                      />
                    </Box>
                    <Typography variant="subtitle1" className={classes.number}>
                      {dataNumber?.procedures_within_academic_year}
                    </Typography>
                  </Box>) : (
                    <Box className={classes.loadingBox}>
                      <RingLoader size={40} color={'#073B70'} loading={isLoading} />
                    </Box>
                  )}
                  <Box>
                    <Typography variant="h3" className={classes.description}>
                      Number of Cases in this Year
                    </Typography>
                  </Box>
                </Paper>
              </Grid>

              <Grid
                item={true}
                xs={12}
                sm={6}
                md={6}
                lg={3}
                xl={3}
                className={classes.item}
              >
                <Paper className={classes.paperOfNum}>
                  {!isLoading ? (<Box className={classes.headerContainer}>
                    <Box className={classes.imgContainer}>
                      <img
                        src={planId && planId == 1 ? locker : calculate}
                        alt="calculate"
                        className={classes.avatarHighlight}
                      />
                    </Box>
                    {!planId ? null : planId != 1 ? (
                      <Typography variant="subtitle1" className={classes.number}>
                        {dataNumber?.total_reimbursable.toFixed(2)}
                      </Typography>) : (
                      <Box>
                        <div className={classes.upgradeBox}>
                          <p>Please <a onClick={() => setisOpen(true)} className={classes.upgrade}>upgrade</a> your plan to unlock this.</p>
                        </div>
                      </Box>
                    )}
                  </Box>) : (
                    <Box className={classes.loadingBox}>
                      <RingLoader size={40} color={'#073B70'} loading={isLoading} />
                    </Box>)}
                  <Box>
                    <Typography variant="h3" className={classes.description}>
                      Estimated Reimbursable RVUs for this year
                    </Typography>
                  </Box>
                </Paper>

              </Grid>

              <Grid
                item={true}
                xs={12}
                sm={6}
                md={6}
                lg={3}
                xl={3}
                className={classes.item}
              >
                <Paper className={classes.paperOfNum}>
                  {!isLoading ? (<Box className={classes.headerContainer}>
                    <Box className={classes.imgContainer}>
                      <img
                        src={planId && planId == 1 ? locker : calculate}
                        alt="calculate"
                        className={classes.avatarHighlight}
                      />
                    </Box>
                    {!planId ? null : planId != 1 ? (<Box>
                      <Typography
                        className={getEstimatedNumberStyle()}
                      >
                        {getFinalEstimatedNumber()}
                      </Typography>
                      <Typography className={classes.boxText}>
                        {typeof estimatedReimbursement === 'number' && estimatedReimbursement > 0 && userInfo?.reimbursement?.reimbursementModel == 'MIXED' ? '(Above Base Salary)' : typeof estimatedReimbursement === 'number' && estimatedReimbursement < 0 && userInfo?.reimbursement?.reimbursementModel == 'MIXED' ? '(Threshold Not Met)' : ''}
                      </Typography>
                    </Box>) : (<Box>
                      <div className={classes.upgradeBox}>
                        <p>Please <a onClick={() => setisOpen(true)} className={classes.upgrade}>upgrade</a> your plan to unlock this.</p>
                      </div>
                    </Box>)}
                  </Box>) : (
                    <Box className={classes.loadingBox}>
                      <RingLoader size={40} color={'#073B70'} loading={isLoading} />
                    </Box>
                  )}
                  <Box>
                    <Typography variant="h3" className={classes.description}>

                      Estimated    <NavLink
                        to={{
                          pathname: '/Profile',
                          state: { panelIndex: 3 }
                        }}
                        className={classes.link}
                      >
                        Reimbursement
                      </NavLink> for this Year
                    </Typography>
                  </Box>
                </Paper>

              </Grid>
            </Grid>
          ) : null}
          <Grid item={true} xs={9} sm={9} md={10} lg={10} xl={6}>
            <Input
              placeholder={'Search by: ' + optionForView}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              }
              className={classes.input}
              value={searchKeyword}
              onChange={(event) => handleSearchChange(event)}
            />
          </Grid>

          <Grid
            item={true}
            xs={3}
            sm={3}
            md={2}
            lg={2}
            xl={2}
            className={classes.searchHeader}
          >
            <FilterListIcon
              className={classes.mobileIconFilter}
              onClick={() => setOpenMenu(true)}
            />{' '}
            {openMenu ? (
              <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
                <Paper
                  className={
                    title === 'dashboard'
                      ? classes.poperFilter
                      : classes.poperFilterRegitry
                  }
                >
                  <MenuItem
                    value="Patient Info"
                    onClick={(event) => {
                      setOptionForView('Patient Info');
                      filterChange('INFO');
                      handleMenuClose(
                        event as unknown as React.MouseEvent<
                          Document,
                          MouseEvent
                        >
                      );
                    }}
                  >
                    Patient Info
                  </MenuItem>

                  <MenuItem
                    onClick={(event) => {
                      setOptionForView('CPT');
                      filterChange('CPT');
                      handleMenuClose(
                        event as unknown as React.MouseEvent<
                          Document,
                          MouseEvent
                        >
                      );
                    }}
                    value="CPT"
                  >
                    CPT
                  </MenuItem>

                  <MenuItem
                    value="Institution"
                    onClick={(event) => {
                      setOptionForView('Institution');
                      filterChange('INSTITUTION');
                      handleMenuClose(
                        event as unknown as React.MouseEvent<
                          Document,
                          MouseEvent
                        >
                      );
                    }}
                  >
                    Institution
                  </MenuItem>
                  <MenuItem
                    value="Procedure"
                    onClick={(event) => {
                      setOptionForView('Procedure');
                      filterChange("PROCEDURE");
                      handleMenuClose(
                        event as unknown as React.MouseEvent<
                          Document,
                          MouseEvent
                        >
                      );
                    }}
                  >
                    Procedure
                  </MenuItem>
                </Paper>
              </ClickAwayListener>
            ) : null}
            <SearchIcon className={classes.mobileIcon} onClick={doSearch} />
          </Grid>

          <Grid
            item={true}
            xs={6}
            sm={6}
            md={3}
            lg={4}
            xl={4}
            className={classes.webView}
          >
            <FormControl className={classes.searchBy}>
              <InputLabel
                shrink
                id="demo-simple-select-placeholder-label-label"
              >
                Search by:
              </InputLabel>
              <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={optionForView}
                onChange={handleChange}
                defaultValue="Patient Info"
              >
                <MenuItem selected value="Patient Info">Patient Info</MenuItem>
                <MenuItem value="Institution">Institution</MenuItem>
                <MenuItem value="Procedure">Procedure</MenuItem>

              </Select>
            </FormControl>
          </Grid>
          <Grid
            item={true}
            xs={1}
            sm={12}
            md={2}
            lg={2}
            className={classes.searchMobile}
          >
            <Button
              className={classes.search}
              onClick={doSearch}
              variant="contained"
              color="secondary"
            >
              <SearchIcon className={classes.icon}></SearchIcon>
              Search
            </Button>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={12} lg={12}>
            <AppBar
              position="static"
              color="default"
              className={classes.AppBar}
            >
              <Tabs
                value={selectedIndex}
                onChange={handleChangeIndex}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
                centered
              >
                <Tab
                  className={classes.filterTab}
                  label="All"
                  {...a11yProps(0)}
                />
                {title === 'dashboard' || title === 'sharedDashboard' ? (
                  <Tab
                    className={classes.filterTab}
                    label="Upcoming"
                    {...a11yProps(1)}
                  />
                ) : null}
                <Tab
                  className={classes.filterTab}
                  label="This week"
                  {...a11yProps(
                    title === 'dashboard' || title === 'sharedDashboard' ? 2 : 1
                  )}
                />
                <Tab
                  className={classes.filterTab}
                  label="This month"
                  {...a11yProps(
                    title === 'dashboard' || title === 'sharedDashboard' ? 3 : 2
                  )}
                />
              </Tabs>
            </AppBar>
          </Grid>

          <Grid
            item={true}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={4}
            className={classes.mobileViewMultiSelect}
          >
            {title === 'dashboard' ||
              title === 'Registry' ||
              (canAddCases && title === 'sharedDashboard') ? (
              <div className={classes.AppBarDiv}>
                <Button
                  onClick={() => addCase()}
                  className={
                    showMultiSelectTab
                      ? classes.headerButtonDisabled
                      : classes.headerButton
                  }
                  variant="contained"
                  color="secondary"
                  disabled={showMultiSelectTab}
                >
                  <AddIcon className={classes.icon}></AddIcon>
                  Add Case
                </Button>
                <Button
                  className={classes.headerButton}
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpenImportCase(true)}
                  disabled={showMultiSelectTab}
                >
                  <GetAppIcon className={classes.icon}></GetAppIcon>
                  Import Case
                </Button>
                {/* <Box
                  style={{ height: 35, marginTop: 15, marginLeft: 10, backgroundColor: 'transparent', boxShadow: 'none' }}
                >
                  <CalendarWithNumbers setCases={setCases} proceduresCount={dataNumber?.procedures_count | 0} />
                </Box> */}

              </div>
            ) : null}
          </Grid>

          <Grid
            item={true}
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.webView}
          >
            <AppBar
              position="static"
              color="default"
              className={classes.AppBar}
            >
              <div className={classes.AppBarDiv}>
                {title === 'dashboard' ||
                  title === 'Registry' ||
                  (canAddCases && title === 'sharedDashboard') ? (
                  <Button
                    onClick={() => addCase()}
                    className={classes.headerButton}
                    variant="contained"
                    color="secondary"
                    disabled={
                      showMultiSelectTab ||
                      (!canAddCases && title === 'sharedDashboard')
                    }
                  >
                    <AddIcon className={classes.icon}></AddIcon>
                    Add Case
                  </Button>
                ) : null}

                {title === 'dashboard' ||
                  title === 'Registry' ||
                  (canAddCases && title === 'sharedDashboard') ? (
                  <Button
                    className={classes.headerButton}
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpenImportCase(true)}
                    disabled={
                      showMultiSelectTab ||
                      (!canAddCases && title === 'sharedDashboard')
                    }
                  >
                    <GetAppIcon className={classes.icon}></GetAppIcon>
                    Import Case
                  </Button>
                ) : null}
                {/* <Box
                  style={{ height: 35, marginTop: 8, backgroundColor: 'transparent', boxShadow: 'none' }}
                >
                  <CalendarWithNumbers setCases={setCases} proceduresCount={dataNumber?.procedures_count | 0} />
                </Box> */}
                {showMultiSelectTab && title !== 'sharedDashboard' ? (
                  <>
                    <ClearIcon
                      color="primary"
                      className={classes.clearIcon}
                      onClick={() => {
                        setShowMultiSelectTab(false);
                        removeSelect();
                      }}
                    />
                    <Button
                      color="primary"
                      onClick={() => {
                        let selectedAllCases;
                        filterCases.length == cases.length
                          ? (selectedAllCases = cases.map((obj) => ({
                            ...obj,
                            isChecked: false
                          })))
                          : (selectedAllCases = cases.map((obj) => ({
                            ...obj,
                            isChecked: true
                          })));
                        setCases(selectedAllCases);
                        setfilterCases(selectedAllCases);
                      }}
                    >
                      {filterCases.length === cases.length ? (
                        <CircleChecked className={classes.icon} />
                      ) : (
                        <CircleUnchecked className={classes.icon} />
                      )}
                      Select all
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => setOpenExportCase(true)}
                    >
                      <OpenInNewIcon className={classes.icon}></OpenInNewIcon>
                      Export
                      {openExportCase ? (
                        <ClickAwayListener
                          onClickAway={() => setOpenExportCase(false)}
                        >
                          <Paper className={classes.poper}>
                            <Button
                              className={classes.exportPoper}
                              onClick={() => {
                                setOpenExportCase(false);
                                pdfExport();
                              }}
                            >
                              <PictureAsPdfIcon />
                              Export to PDF file
                            </Button>
                            <Button
                              className={classes.exportPoper}
                              onClick={() => {
                                setOpenExportCase(false);
                                excelExport();
                              }}
                            >
                              <DescriptionIcon />
                              Export to Excel file
                            </Button>
                            {!registryId ? (
                              <Button
                                className={classes.exportPoper}
                                onClick={() => {
                                  setOpenExportCase(false);
                                  setOpenExportCaseDialog(true);
                                }}
                              >
                                <FolderIcon />
                                Export to Registries
                              </Button>
                            ) : null}
                          </Paper>
                        </ClickAwayListener>
                      ) : null}
                    </Button>
                    <Button
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenConfirm(true);
                      }}
                    >
                      <DeleteIcon />
                      Delete
                    </Button>
                  </>
                ) : null}
                <TablePagination
                  className={classes.Pagination}
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handlePageChange}
                  rowsPerPage={pageLimit}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage="Items per page"
                  rowsPerPageOptions={[12, 25, 50, 100]}
                />
              </div>
            </AppBar>
          </Grid>

          <Grid
            item={true}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            container
            alignItems="center"
            className={classes.mobileViewMultiSelect}
          >
            {' '}
            {showMultiSelectTab && title !== 'sharedDashboard' ? (
              <Box className={classes.multiSelect} mt={2}>
                <div className={classes.AppBarDiv}>
                  <>
                    <ClearIcon
                      color="primary"
                      className={classes.clearIcon}
                      onClick={() => {
                        setShowMultiSelectTab(false);
                        removeSelect();
                      }}
                    />
                    <Button
                      color="primary"
                      onClick={() => {
                        let selectedAllCases;
                        filterCases.length == cases.length
                          ? (selectedAllCases = cases.map((obj) => ({
                            ...obj,
                            isChecked: false
                          })))
                          : (selectedAllCases = cases.map((obj) => ({
                            ...obj,
                            isChecked: true
                          })));
                        setCases(selectedAllCases);
                        setfilterCases(selectedAllCases);
                      }}
                    >
                      {filterCases.length === cases.length ? (
                        <CircleChecked className={classes.icon} />
                      ) : (
                        <CircleUnchecked className={classes.icon} />
                      )}
                      Select all
                    </Button>
                    <Button
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenExportCase(true);
                      }}
                    >
                      <OpenInNewIcon className={classes.icon}></OpenInNewIcon>
                      Export
                      {openExportCase ? (
                        <ClickAwayListener
                          onClickAway={() => setOpenExportCase(false)}
                        >
                          <Paper className={classes.poper}>
                            <Button
                              className={classes.exportPoper}
                              onTouchStart={() => {
                                setOpenExportCase(false);
                                pdfExport();
                              }}
                            >
                              <PictureAsPdfIcon />
                              Export to PDF file
                            </Button>
                            <Button
                              className={classes.exportPoper}
                              onTouchStart={() => {
                                setOpenExportCase(false);
                                excelExport();
                              }}
                            >
                              <DescriptionIcon />
                              Export to Excel file
                            </Button>
                            {!registryId ? (
                              <Button
                                className={classes.exportPoper}
                                onTouchStart={() => {
                                  setOpenExportCase(false);
                                  setOpenExportCaseDialog(true);
                                }}
                              >
                                <FolderIcon />
                                Export to Registries
                              </Button>
                            ) : null}
                          </Paper>
                        </ClickAwayListener>
                      ) : null}
                    </Button>
                    <Button
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenConfirm(true);
                      }}
                    >
                      <DeleteIcon />
                      Delete
                    </Button>
                  </>
                </div>
              </Box>
            ) : null}
          </Grid>
          <Grid
            item={true}
            container
            spacing={0}
            className={classes.casesContainer}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TabPanel value={selectedIndex} index={0}>
                <Case
                  title={title}
                  filterCases={filterCases}
                  cases={cases}
                  openCaseDetails={openCaseDetails}
                  performSearch={performSearch}
                  addCase={addCase}
                  setShowMultiSelectTab={setShowMultiSelectTab}
                  setfilterCases={setfilterCases}
                />
              </TabPanel>
              {title === 'dashboard' || title === 'sharedDashboard' ? (
                <TabPanel value={selectedIndex} index={1}>
                  <Case
                    title={title}
                    filterCases={filterCases}
                    cases={cases}
                    openCaseDetails={openCaseDetails}
                    performSearch={performSearch}
                    addCase={addCase}
                    setShowMultiSelectTab={setShowMultiSelectTab}
                    setfilterCases={setfilterCases}
                  />
                </TabPanel>
              ) : null}
              <TabPanel
                value={selectedIndex}
                index={
                  title === 'dashboard' || title === 'sharedDashboard' ? 2 : 1
                }
              >
                <Case
                  title={title}
                  filterCases={filterCases}
                  cases={cases}
                  openCaseDetails={openCaseDetails}
                  performSearch={performSearch}
                  addCase={addCase}
                  setShowMultiSelectTab={setShowMultiSelectTab}
                  setfilterCases={setfilterCases}
                />
              </TabPanel>
              <TabPanel
                value={selectedIndex}
                index={
                  title === 'dashboard' || title === 'sharedDashboard' ? 3 : 2
                }
              >
                <Case
                  title={title}
                  filterCases={filterCases}
                  cases={cases}
                  openCaseDetails={openCaseDetails}
                  performSearch={performSearch}
                  addCase={addCase}
                  setShowMultiSelectTab={setShowMultiSelectTab}
                  setfilterCases={setfilterCases}
                />
              </TabPanel>
            </Grid>
            <ImportCase
              registryId={registryId}
              isOpen={openImportCase}
              title={'Import Cases'}
              firstOption={'Select File'}
              handleOptionOne={onDelete}
              setImportCaseMsg={setImportCaseMsg}
              setShowImportCaseMsg={setShowImportCaseMsg}
              setIsImported={setIsImported}
              userId={user?.id}
              onClose={() => setOpenImportCase(false)}
            ></ImportCase>
            <RegistryDialog
              isOpen={openExportCaseDialog}
              title={'Export to registries'}
              filterId={getCaseIdForExport()}
              setImportCaseMsg={setImportCaseMsgReg}
              setShowImportCaseMsg={setShowImportCaseMsgReg}
              onClose={() => setOpenExportCaseDialog(false)}
            ></RegistryDialog>
          </Grid>
          <Grid item xs={12} className={classes.mobileFooter}>
            <TablePagination
              className={[classes.mobileView, classes.mobilePagination].join(
                ' '
              )}
              component="div"
              count={count}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={pageLimit}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Items per page"
              rowsPerPageOptions={[12, 25, 50, 100]}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default withWidth()(Dashboard);
