/* eslint-disable no-useless-concat */
import { useRadioGroup } from '@material-ui/core';
import toCamelCaseKeys from 'camelcase-keys';
import { getCookie, setCookie } from './sessionUtils';

export function formatPhoneNumber(phoneNumberString = '') {
  const curchr = phoneNumberString.length;
  let curval = phoneNumberString;
  if (curchr === 3 && curval.indexOf('(') <= -1) {
    curval = '(' + curval + ')' + '-';
  } else if (curchr === 4 && curval.indexOf('(') > -1) {
    curval = curval + ')-';
  } else if (curchr === 5 && curval.indexOf(')') > -1) {
    curval = curval + '-';
  } else if (curchr === 9) {
    curval = curval + '-';
  }
  return curval;
}

export function clearFormatedPhoneNumber(formatedPhoneNumber) {
  const cleaned = ('' + formatedPhoneNumber).replace(/\D/g, '');
  return cleaned;
}

export function getAge(birthDate) {
  if (birthDate !== undefined) {
    const date = birthDate && birthDate.split('-');

    const year = new Date().getFullYear() - new Date(date).getFullYear();
    const month = new Date().getMonth() + 1 - (new Date(date).getMonth() + 1);
    const day = new Date().getDate() - new Date(date).getDate();

    if (year > 0) {
      return `${year}y`;
    } else if (month > 0) {
      return `${month}m`;
    } else {
      return `${day}d`;
    }
  }
}

export function toCamelCase(object) {
  return toCamelCaseKeys(object, { deep: true });
}

export function getUser() {
  let parsedUser = null;
  const user = localStorage.getItem('user');
  if (user) {
    parsedUser = JSON.parse(unescape(user));
  }
  return parsedUser;
}

export function setUser(user) {
  setCookie('user', JSON.stringify(user));
  localStorage.setItem('user', JSON.stringify(user));
}

export const userTheme = () => {
  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    localStorage.setItem('Theme', 'darkTheme');
  } else if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: light)').matches
  ) {
    localStorage.setItem('Theme', 'lightTheme');
  }
};
