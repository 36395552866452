import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
  Grid,
  CircularProgress,
  Menu,
  MenuItem,
  TextField,
  Snackbar,
  ListItem,
  Collapse,
  List,
  IconButton,
  DialogTitle
} from '@material-ui/core';
import { getUser } from '../../../utils';
import * as api from '../../../services/apiService';
import ClearIcon from '@material-ui/icons/Clear';
import Alert from '@material-ui/lab/Alert';
import history from '../../../utils/history';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import caseDetailsStyle from './Styles';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LinesEllipsis from 'react-lines-ellipsis';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import UpgradeDialog from '../../../components/Dialogs/UpgradeDialog';
import {
  caseProcedureType,
  cptModifierType,
  hospitalDataType,
  mediaType,
  procedureDataType,
  rolesDataType
} from '../../../types';
import DescriptionIcon from '@material-ui/icons/Description';
import FolderIcon from '@material-ui/icons/Folder';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DeleteDialog from '../../../components/Dialogs/DeleteDialog';
import { object } from 'yup';
import RegistryDialog from '../../../components/Dialogs/RegistryDialog';
import CaseProcedureDetails from '../../../components/CaseProcedureDetails/CaseProcedureDetails';
import AddIcon from '@material-ui/icons/Add';
import { Control, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloseIcon from '@material-ui/icons/Close';

type caseParams = {
  caseId: string;
  registryId?: string;
  doctorId?: string;
};

const CaseDetails = (props: any) => {
  const [isExpanded, setIsExpanded] = useState<boolean[]>([]);

  const toggleExpand = (index) => {
    const prev = [...isExpanded];
    prev[index] = !prev[index];
    setIsExpanded(prev);
  };

  const classes = caseDetailsStyle();
  const { caseId, registryId, doctorId } = useParams<caseParams>();
  const normalData = {
    patient_first_name: '',
    patient_last_name: '',
    date_of_birth: '',
    mrn: null,
    age: null,
    primary_surgeon: null,
    race: '',
    insurance: '',
    gender: '',
    date: '',
    type: null,
    procedure_name: '',
    approach: '',
    minimally_invasive: '',
    navigation: '',
    robotic: '',
    indication: '',
    side: '',
    implant: '',
    implant_type: '',
    location: '',
    osteotomy: '',
    _fusion: '',
    fusion_levels: '',
    decompression_levels: '',
    interbody_fusion: '',
    extension_to_pelvis: '',
    morphogenic_protein: '',
    instrumentation: '',
    target: '',
    nerve: '',
    _procedure: '',
    tici_grade: '',
    access: '',
    vascular_closure_device: '',
    duration_of_radiation: '',
    duration_of_procedure: '',
    date_of_procedure: '',
    role: null,
    notes: [],
    length_in_minutes: null,
    vendors: null,
    complications: null,
    findings: null,
    outcome: null,
    procedure_diagnosis: null,
    length_of_hospitalizations_in_days: null,
    follow_up_number: 0,
    follow_up_period: '',
    additional_info: ''
  };

  const registryData = {
    patient_first_name: '',
    patient_last_name: '',
    date_of_birth: '',
    mrn: null,
    images: null,
    newNote: null,
    age: null,
    primary_surgeon: null,
    race: '',
    institute: null,
    diagnosisCodes: null,
    cptCodes: null,
    insurance: '',
    gender: '',
    date: '',
    type: null,
    procedure_name: '',
    approach: '',
    minimally_invasive: '',
    navigation: '',
    robotic: '',
    indication: '',
    side: '',
    implant: '',
    implant_type: '',
    location: '',
    osteotomy: '',
    _fusion: '',
    fusion_levels: '',
    decompression_levels: '',
    interbody_fusion: '',
    extension_to_pelvis: '',
    morphogenic_protein: '',
    instrumentation: '',
    target: '',
    nerve: '',
    _procedure: '',
    tici_grade: '',
    access: '',
    vascular_closure_device: '',
    duration_of_radiation: '',
    duration_of_procedure: '',
    date_of_procedure: '',
    role: null,
    notes: [],
    length_in_minutes: null,
    vendors: null,
    complications: null,
    findings: null,
    outcome: null,
    procedure_diagnosis: null,
    length_of_hospitalizations_in_days: null,
    follow_up_number: 0,
    follow_up_period: '',
    additional_info: ''
  };
  const [caseIndicator, setCaseIndicator] = React.useState<boolean>(false);
  const [openExportCase, setOpenExportCase] = React.useState<boolean>(false);
  const [
    showImportCaseMsgReg,
    setShowImportCaseMsgReg
  ] = React.useState<boolean>(false);
  const [sumOfRvu, setSumOfRvu] = React.useState<number>(0);
  const [totalReimbursable, setTotalReimbursable] = React.useState<number>(0);
  const [importCaseMsgReg, setImportCaseMsgReg] = React.useState<string[]>([]);
  const [
    openExportCaseDialog,
    setOpenExportCaseDialog
  ] = React.useState<boolean>(false);
  const [procedureData, setProcedureData] = React.useState<procedureDataType>(
    registryId ? registryData : normalData
  );
  const [caseProcedures, setCaseProcedures] = React.useState<caseProcedureType[]>([]);

  const [hospitalData, setHospitalData] = React.useState<hospitalDataType>({
    name: ''
  });
  const [roleList, setRoleList] = React.useState<rolesDataType[]>([]);
  const [procedureCPTData, setProcedureCPTData] = React.useState<any[]>([]);
  const [registryFields, setRegistryFields] = React.useState<any[]>([]);
  const [procedureICDData, setprocedureICDData] = React.useState<any[]>([]);
  const [media, setMedia] = React.useState<mediaType[]>([
    { deleted_flag: false, image_name: '', id: '' }
  ]);
  const [previewImage, setPreviewImage] = React.useState<string>('');
  const [openImage, setOpenImage] = React.useState<boolean>(false);

  const [open, setOpen] = React.useState<boolean>(false);
  const [openCOllapse, setOpenCOllapse] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string[]>([]);
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  const [isOpenConfirm, setIsOpenConfirm] = React.useState<boolean>(false);
  const [registryName, setRegistryName] = React.useState<string>();
  const [customizedField, setCustomizedField] = React.useState<string[]>([]);
  const [customizedData, setCustomizedData] = React.useState<any[]>([]);
  const [cptTitle, setCptTitle] = React.useState<any[]>([]);
  const [normalFields, setNormalFields] = React.useState<any[]>([]);
  const [tokenError, setTokenError] = React.useState<boolean>(false);
  const [userToken, setUserToken] = React.useState<string>('');
  const [isOpen, setisOpen] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState<boolean>(false);
  const [currentPlan, setCurrentPlan] = React.useState<any>();
  const [messageError, setMessageError] = React.useState<string>('');
  const [newPlan, setNewPlan] = React.useState<boolean>(false);
  const [cptModifier, setCPTModifier] = React.useState<cptModifierType>();
  const user = getUser();
  const location = useLocation().pathname;
  const [showAddNoteText, setShowAddNoteText] = React.useState<boolean>(false);
  const [showEditNotes, setShowEditNotes] = React.useState<boolean>(false);
  const [showEditImages, setShowEditImages] = React.useState<boolean>(false);
  const [notes, setNotes] = React.useState<string[]>([]);
  const [editNoteField, setEditNoteField] = React.useState<string>('');
  const [notesHasChanged, setNotesHasChanged] = React.useState<boolean>(false);
  const [imagesFetched, setImagesFetched] = React.useState<boolean>(false);
  const [images, setImages] = React.useState<any[]>([]);
  const [imageIndex, setImageIndex] = React.useState<any>();
  const [imageId, setImageId] = React.useState<any>();
  const [openDeleteImage, setOpenDeleteImage] = React.useState<boolean>(false);
  const [openedImage, setOpenedImage] = useState<any>(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState<any>(null);
  const [newImageName, setNewImageName] = useState('');
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);

  const notesValidationSchema = yup.object().shape({
    notes: yup.array().of(
      yup.object().shape({
        notes: yup.string().nullable(false)
      })
    )
  });

  const {
    handleSubmit,
    errors,
    getValues,
    control,
    setValue,
    register,
    watch,
    reset
  } = useForm({
    defaultValues: notes,
    resolver: yupResolver(notesValidationSchema),
    mode: 'all'
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control: control as any,
      name: 'notes',
    }
  );

  const handleNoteChange = (value, index) => {
    fields[index].note = value;
  }

  const updateNotes = async () => {
    const { data, status_code: statusCode } = await api.updateNotes(user.id, caseId, fields);
    if (statusCode === api.status.SUCCESS) {
      setShowEditNotes(false);
      getProcedureById();
    }
  }

  const getCurrentPlan = React.useCallback(async () => {
    const { data, status_code: statusCode } = await api.getCurrentPlan();
    if (statusCode === api.status.SUCCESS) {
      setCurrentPlan(data);
    }
  }, []);

  const getUserToken = React.useCallback(async () => {
    const { data, status_code: statusCode } = await api.getUserToken();
    setNewPlan(false);
    if (statusCode === api.status.SUCCESS) {
      setUserToken(data.token);
    } else if (statusCode === api.status.ERROR_UPDATE) {
      setTokenError(true);
      setMessageError(data);
    }
  }, []);


  const getRoles = async () => {
    const { data, status_code: statusCode } = await api.getRoles();
    if (statusCode === api.status.SUCCESS) {
      const roleList = Object.keys(data).map((key) => ({
        value: key,
        label: data[key]
      }));
      setRoleList(roleList);
    }


  };


  React.useEffect(() => {
    getUserToken();
    if (isOpen) {
      getCurrentPlan();
    }
  }, [isOpen]);

  useEffect(() => {
    let maxRvu = 0;
    let sumOfRestOfRvus = 0;
    let sumRvu = procedureCPTData.reduce(function (sum, item) {
      return (sum = sum + item.cpt_lookup.rvu_value);
    }, 0);
    setSumOfRvu(sumRvu);
    for (let i = 0; i < procedureCPTData.length; i++) {
      if (procedureCPTData[i].cpt_lookup.rvu_value > maxRvu) {
        maxRvu = procedureCPTData[i].cpt_lookup.rvu_value;
      }
    }
    sumOfRestOfRvus = sumRvu - maxRvu;
    const sumOfTotalReimbursable = maxRvu + (sumOfRestOfRvus * 0.5);
    setTotalReimbursable(sumOfTotalReimbursable);
  }, [procedureCPTData])

  const getProcedureImages = React.useCallback(async () => {
    console.log('gg images');
    const { data, status_code: statusCode } = await api.getProcedureMedia(user.id, props.caseId === undefined ? caseId : props.caseId);
    if (statusCode === api.status.SUCCESS) {
      setImages(data);
      setMedia(data);
    }
  }, [caseId, setValue, user.id])


  const getProcedureById = React.useCallback(async () => {
    setCaseIndicator(true);

    if (props.caseId || caseId) {
      const { data, status_code: statusCode } = await api.getProcedureById(
        user?.id,
        props.caseId === undefined ? caseId : props.caseId
      );
      if (statusCode === api.status.SUCCESS) {
        for (let key in data) {
          data[key] = data[key] == 'undefined' ? '' : data[key];
        }
        if (data.implant != null) {
          if (data.implant == true) {
            data.implant = 'Yes';
          } else {
            data.implant = 'No';
          }
        } else {
          data.implant = '';
        }

        if (data._fusion != null) {
          if (data._fusion == true) {
            data._fusion = 'Yes';
          } else {
            data._fusion = 'No';
          }
        } else {
          data._fusion = '';
        }

        if (data.interbody_fusion != null) {
          if (data.interbody_fusion == true) {
            data.interbody_fusion = 'Yes';
          } else {
            data.interbody_fusion = 'No';
          }
        } else {
          data.interbody_fusion = '';
        }

        if (data.extension_to_pelvis != null) {
          if (data.extension_to_pelvis == true) {
            data.extension_to_pelvis = 'Yes';
          } else {
            data.extension_to_pelvis = 'No';
          }
        } else {
          data.extension_to_pelvis = '';
        }

        if (data.morphogenic_protein != null) {
          if (data.morphogenic_protein == true) {
            data.morphogenic_protein = 'Yes';
          } else {
            data.morphogenic_protein = 'No';
          }
        } else {
          data.morphogenic_protein = '';
        }

        data['minimally_invasive'] = data['minimally_invasive'] === true ? 'Yes' : data['minimally_invasive'] === false ? 'No' : null;
        data['navigation'] = data['navigation'] === true ? 'Yes' : data['navigation'] === false ? 'No' : null;
        data['robotic'] = data['robotic'] === true ? 'Yes' : data['robotic'] === false ? 'No' : null;

        setProcedureData(data);

        for (let procedure of data.case_procedures) {
          procedure['implant'] = procedure['implant'] === true ? 'Yes' : procedure['implant'] === false ? 'No' : null;
          procedure['_fusion'] = procedure['_fusion'] === true ? 'Yes' : procedure['_fusion'] === false ? 'No' : null;
          procedure['interbody_fusion'] = procedure['interbody_fusion'] === true ? 'Yes' : procedure['interbody_fusion'] === false ? 'No' : null;
          procedure['extension_to_pelvis'] = procedure['extension_to_pelvis'] === true ? 'Yes' : procedure['extension_to_pelvis'] === false ? 'No' : null;
          procedure['morphogenic_protein'] = procedure['morphogenic_protein'] === true ? 'Yes' : procedure['morphogenic_protein'] === false ? 'No' : null;
          procedure['minimally_invasive'] = procedure['minimally_invasive'] === true ? 'Yes' : procedure['minimally_invasive'] === false ? 'No' : null;
          procedure['navigation'] = procedure['navigation'] === true ? 'Yes' : procedure['navigation'] === false ? 'No' : null;
          procedure['robotic'] = procedure['robotic'] === true ? 'Yes' : procedure['robotic'] === false ? 'No' : null;

        }

        setCPTModifier(data.cpt_modifier);

        setCaseProcedures(data.case_procedures);
        setHospitalData(data.hospital);
        setProcedureCPTData(data.procedure_cpt);
        setprocedureICDData(data.procedure_icd);
        getProcedureImages();
        reset();
        data.notes.forEach((item) => {
          append(item);
        });
      }
      setCaseIndicator(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [procedureData.patient_first_name, props.caseId, user?.id]);

  const getSharedProcedureById = React.useCallback(async () => {
    setCaseIndicator(true);

    if (props.caseId || caseId) {
      const { data, status_code: statusCode } = await api.getProcedureById(
        doctorId,
        props.caseId === undefined ? caseId : props.caseId
      );
      if (statusCode === api.status.SUCCESS) {
        setProcedureData(data);
        setHospitalData(data.hospital);
        setProcedureCPTData(data.procedure_cpt);
        setprocedureICDData(data.procedure_icd);
        getProcedureImages();
      }
      setCaseIndicator(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [procedureData.patient_first_name, props.caseId, doctorId]);

  const checkCustomizedField = React.useCallback(async (dataProcedure) => {
    const keys = Object.keys(dataProcedure);
    let fields;
    let isAdmin;

    const { data, status_code: statusCode } = await api.getRegistryById(
      registryId
    );
    if (statusCode === api.status.SUCCESS) {
      setRegistryName(data.name);
      fields = data.fields;
      if (data.owner.id === user.id) {
        isAdmin = true;
      } else {
        isAdmin = false;
      }
    }
    let allFieldsRegistry = fields.map((x) => {
      return Object.values(x)[0];
    });
    setRegistryFields(allFieldsRegistry);
    let res = fields.map((x) => {
      if (x.visible) {
        return Object.values(x)[0];
      }
    });
    let customVissibleFields = res.filter((el) => {
      return el != null && el != '';
    });
    const procedureDatakeys = Object.keys(procedureData);
    let customizedField = keys.filter(
      (x) => procedureDatakeys.indexOf(x) === -1
    );
    let vissibleFields;
    if (!isAdmin) {
      vissibleFields = customizedField.filter(
        (x) => customVissibleFields.indexOf(x) !== -1
      );
    } else {
      vissibleFields = customizedField;
    }

    let newCpt: any = [];
    let newNormalField: any = [];
    for (let i = 0; i < vissibleFields.length; i++) {
      let fieldTitle = vissibleFields[i];
      if (
        typeof dataProcedure[fieldTitle] === 'object' &&
        Array.isArray(dataProcedure[fieldTitle])
      ) {
        newCpt.push(fieldTitle);
      } else {
        newNormalField.push(fieldTitle);
      }
      setCptTitle(newCpt);
      setNormalFields(newNormalField);
    }
    setCustomizedField(vissibleFields);
  }, []);

  const getDataForCase = async () => {
    const {
      data,
      status_code: statusCode
    } = await api.getRegistryProcedureById(
      registryId,
      caseId === undefined ? caseId : caseId
    );
    if (statusCode === api.status.SUCCESS) {
      setProcedureData(data.data);
      setCustomizedData(data.data);
      const hospital = {
        id: data.data.institute.id,
        name: data.data.institute.label
      };
      setHospitalData(hospital);
      setProcedureCPTData(data.data.cptCodes);
      setprocedureICDData(data.data.diagnosisCodes);
      let images;
      if (data.data.images) {
        images = [...data.data.images];
      } else {
        images = [];
      }

      let media: any[] = [];
      for (let i = 0; i < images.length; i++) {
        const photo = {
          deleted_flag: false,
          image_name: images[i].name,
          id: images[i].id,
          urls: images[i].url
        };
        media.push(photo);
      }
      setMedia(media);
    }
  };
  const getRegistryProcedureById = React.useCallback(async () => {
    setCaseIndicator(true);

    if ((props.caseId || caseId) && procedureData.patient_first_name === '') {
      getDataForCase();
      setCaseIndicator(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.caseId, user?.id]);

  useEffect(() => {
    getRoles();
    if (location.includes('/registrycase/')) {
      if (procedureData.patient_first_name === '') {
        getRegistryProcedureById();
      }
    } else if (location.includes('/case/')) {
      if (procedureData.patient_first_name === '') {
        getProcedureById();
      }
    } else if (location.includes('/shared-case/')) {
      if (procedureData.patient_first_name === '') {
        getSharedProcedureById();
      }
    }
  }, []);
  useEffect(() => {
    if (
      procedureData.patient_first_name !== '' &&
      location.includes('/registrycase/')
    ) {
      checkCustomizedField(procedureData);
    }
  }, [procedureData]);
  const editCase = (caseId: any) => {
    history.push('/edit-case/' + caseId);
  };

  const editSharedCase = (caseId: any, doctorId: any) => {
    history.push('/edit-shared-case/' + doctorId + '/' + caseId);
  };

  const editRegistryCase = (caseId, registryId) => {
    history.push('/edit-Registry-case/' + registryId + '/' + caseId);
  };

  const handleClose = () => {
    setCaseIndicator(false);
  };

  const previewImageDialog = (src: any) => {
    setPreviewImage(src);
    openImagePreview();
  };

  const handleImageClose = () => {
    setOpenImage(false);
  };

  const openImagePreview = () => {
    setOpenImage(true);
  };

  const deleteRegistryProcedureById = async () => {
    if (props.caseId || caseId) {
      setCaseIndicator(true);
      const {
        status_code: statusCode,
        data
      } = await api.deleteRegistryProcedureById(registryId, caseId);
      if (statusCode === api.status.SUCCESS) {
        handleDetailsClose();
        props.setShowEditCaseMessage(true);
        let message = ['success', 'Procedure deleted successfully!'];
        props.setCaseMessage(message);
        setTimeout(() => props.setShowEditCaseMessage(false), 1000);
        history.push('/registry/' + registryId);
      } else {
        props.setShowEditCaseMessage(true);
        let message = ['error', { data }];
        props.setCaseMessage(message);
        setTimeout(() => props.setShowEditCaseMessage(false), 1000);
      }
      setCaseIndicator(false);
    }
  };

  const deleteProcedureById = async () => {
    if (props.caseId || caseId) {
      setCaseIndicator(true);
      const { status_code: statusCode, data } = await api.deleteProcedureById(
        user?.id,
        props.caseId === undefined ? caseId : props.caseId
      );
      if (statusCode === api.status.SUCCESS) {
        handleDetailsClose();
        props.setShowEditCaseMessage(true);
        let message = ['success', 'Procedure deleted successfully!'];
        props.setCaseMessage(message);
        setTimeout(() => props.setShowEditCaseMessage(false), 1000);
      } else {
        props.setShowEditCaseMessage(true);
        let message = ['error', { data }];
        props.setCaseMessage(message);
        setTimeout(() => props.setShowEditCaseMessage(false), 1000);
      }
      setCaseIndicator(false);
    }
  };

  const handleDetailsClose = () => {
    history.push('/dashboard');
  };

  const handleClickOpen = () => {
    setOpenCOllapse(!openCOllapse);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const excelRegistryExport = async () => {
    setCaseIndicator(true);
    const { data, status_code: statusCode } = await api.exportRegistryExcelCase(
      registryId,
      {
        procedure_ids: props.caseId === undefined ? caseId : props.caseId
      }
    );
    if (statusCode === api.status.SUCCESS) {
      setShowMessage(true);
      let message = [
        'success',
        'Exporting now. Once done, you will find the files in your Profile under Exported Procedures.'
      ];
      setMessage(message);
      setTimeout(() => setShowMessage(false), 3000);
    } else {
      setShowMessage(true);
      let message = ['error', `${data}`];
      setMessage(message);
      setTimeout(() => setShowMessage(false), 1000);
    }
    if (statusCode === api.status.SUCCESS && data.path) {
    }
    handleDialogClose();
    setCaseIndicator(false);
  };

  const excelExport = async () => {
    setCaseIndicator(true);
    const { data, status_code: statusCode } = await api.exportExcelCase(
      user?.id,
      {
        type: 'EXCEL',
        procedure_ids: [props.caseId === undefined ? caseId : props.caseId]
      }
    );
    if (statusCode === api.status.SUCCESS) {
      setShowMessage(true);
      let message = [
        'success',
        'Exporting now. Once done, you will find the files in your Profile under Exported Procedures.'
      ];
      setMessage(message);
      setTimeout(() => setShowMessage(false), 3000);
    } else {
      setShowMessage(true);
      let message = ['error', `${data}`];
      setMessage(message);
      setTimeout(() => setShowMessage(false), 1000);
    }
    if (statusCode === api.status.SUCCESS && data.path) {
    }
    handleDialogClose();
    setCaseIndicator(false);
  };
  const getSeverity = (color: any) => {
    return color;
  };
  const goToRegistry = (id) => {
    const newId = parseInt(id, 10);
    history.push('/registry/' + newId);
  };

  const pdfRegistryExport = async () => {
    setCaseIndicator(true);
    const { data, status_code: statusCode } = await api.exportRegistryPDFCase(
      registryId,
      {
        procedure_ids: props.caseId === undefined ? caseId : props.caseId
      }
    );
    if (statusCode === api.status.SUCCESS) {
      setShowMessage(true);
      let message = [
        'success',
        'Exporting now. Once done, you will find the files in your Profile under Exported Procedures.'
      ];
      setMessage(message);
      setTimeout(() => setShowMessage(false), 3000);
    } else {
      setShowMessage(true);
      let message = ['error', `${data}`];
      setMessage(message);
      setTimeout(() => setShowMessage(false), 1000);
    }
    if (statusCode === api.status.SUCCESS && data.path) {
      const link = document.createElement('a');
      link.href = data.path;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    handleDialogClose();
    setCaseIndicator(false);
  };

  const pdfExport = async () => {
    setCaseIndicator(true);
    const { data, status_code: statusCode } = await api.exportPDFCase(
      user?.id,
      {
        type: 'PDF',
        procedure_ids: [props.caseId === undefined ? caseId : props.caseId]
      }
    );
    if (statusCode === api.status.SUCCESS) {
      setShowMessage(true);
      let message = [
        'success',
        'Exporting now. Once done, you will find the files in your Profile under Exported Procedures.'
      ];
      setMessage(message);
      setTimeout(() => setShowMessage(false), 3000);
    } else {
      setShowMessage(true);
      let message = ['error', `${data}`];
      setMessage(message);
      setTimeout(() => setShowMessage(false), 1000);
    }
    if (statusCode === api.status.SUCCESS && data.path) {
      const link = document.createElement('a');
      link.href = data.path;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    handleDialogClose();
    setCaseIndicator(false);
  };

  const onDelete = () => {
    handleCloseConfirm();
    if (registryId) {
      deleteRegistryProcedureById();
    }
    deleteProcedureById();
  };

  const handleCloseConfirm = () => {
    setIsOpenConfirm(false);
  };

  const displayCustomFields = (name, field) => {
    return <div></div>;
  };

  const handleCloseDialog = () => {
    setisOpen(false);
  };

  const isNeurosurgery = () => {
    return user.user_profile[0].speciality?.name === 'Neurological Surgery'
  }

  const isInterventional = () => {
    return user.user_profile[0].speciality?.name == 'Interventional Neurology/Neuroradiology';
  }

  const isRadiology = () => {
    return user.user_profile[0].speciality?.name == 'Interventional Radiology';
  }

  const isEmptyIndication = () => {
    procedureData.indication = procedureData.indication === null ? '' : procedureData.indication;
    return procedureData.indication === '' || (procedureData.indication && procedureData.indication.split(', ').includes(''));
  }

  const isEmptyProcedure = () => {
    return procedureData.type === '';
  }

  const isOtherIndication = () => {
    procedureData.indication = procedureData.indication === null ? '' : procedureData.indication;
    return procedureData.indication === 'Other' || (procedureData.indication && procedureData.indication.split(', ').includes('Other'));
  }

  const isOtherProcedure = () => {
    return procedureData.type === 'Other';
  }

  const isTumor = () => {
    return procedureData.indication === 'Tumor' || (procedureData.indication && procedureData.indication.split(', ').includes('Tumor'));
  }

  const isPainIndication = () => {
    return procedureData.indication === 'Pain' || (procedureData.indication && procedureData.indication.split(', ').includes(''));
  }

  const isCarotid = () => {
    return procedureData.indication === 'Carotid stenosis' || (procedureData.indication && procedureData.indication.split(', ').includes('Carotid stenosis'));
  }
  const isAVF = () => {
    return procedureData.indication === 'AVF' || (procedureData.indication && procedureData.indication.split(', ').includes('AVF'));
  }

  const isAVM = () => {
    return procedureData.indication === 'AVM' || (procedureData.indication && procedureData.indication.split(', ').includes('AVM'));
  }

  const isAbscess = () => {
    return procedureData.indication === 'Abscess' || (procedureData.indication && procedureData.indication.split(', ').includes('Abscess'));
  }

  const isAneurysm = () => {
    return procedureData.indication === 'Aneurysm' || (procedureData.indication && procedureData.indication.split(', ').includes('Aneurysm'));
  }

  const isHematoma = () => {
    return procedureData.indication === 'Hematoma' || (procedureData.indication && procedureData.indication.split(', ').includes('Hematoma'));
  }

  const isFracture = () => {
    return procedureData.indication === 'Fracture' || (procedureData.indication && procedureData.indication.split(', ').includes('Fracture'));
  }

  const isHydrocephalus = () => {
    return procedureData.indication === 'Hydrocephalus' || (procedureData.indication && procedureData.indication.split(', ').includes('Hydrocephalus'));
  }

  const isBleeding = () => {
    return procedureData.indication === 'Bleeding' || (procedureData.indication && procedureData.indication.split(', ').includes('Bleeding'));
  }

  const isDissection = () => {
    return procedureData.indication === 'Dissection' || (procedureData.indication && procedureData.indication.split(', ').includes('Dissection'));
  }

  const isVasospasm = () => {
    return procedureData.indication === 'Vasospasm' || (procedureData.indication && procedureData.indication.split(', ').includes('Vasospasm'));
  }

  const isStroke = () => {
    return procedureData.indication === 'Stroke' || (procedureData.indication && procedureData.indication.split(', ').includes('Stroke'));
  }

  const isThrombectomy = () => {
    return procedureData._procedure === 'Thrombectomy' || (procedureData._procedure && procedureData._procedure.split(', ').includes('Thrombectomy'));
  }

  const isCranial = () => {
    return procedureData.type === 'Cranial';
  }

  const isSpine = () => {
    return procedureData.type === 'Spine';
  }

  const isFunctional = () => {
    return procedureData.type === 'Functional';
  }

  const isPeripheral = () => {
    return procedureData.type === 'Peripheral nerves';
  }

  const isInter = () => {
    return procedureData.type === 'Neurointerventional';
  }

  const isBedside = () => {
    return procedureData.type === 'Bedside';
  }

  const isPain = () => {
    return procedureData.type === 'Pain';
  }

  const isRadiosurgery = () => {
    return procedureData.type === 'Radiosurgery';
  }

  const showLocation = () => {
    return (((isNeurosurgery() && isInter()) && (isCarotid() || isPainIndication() || isOtherIndication()) ||
      (isInterventional() && (isCarotid() || isPainIndication() || isOtherIndication()))) ||
      ((isNeurosurgery() && !(isFunctional() || isPeripheral())) &&
        !(isEmptyIndication() || isOtherIndication()) ||
        isNeurosurgery() && isSpine()) ||
      (isInterventional() && !isEmptyIndication()) || isRadiology());
  }

  const getCPTModifierPercentage = () => {
    let originalPercentage = Number(cptModifier?.percentage);
    let percentage = originalPercentage % 1 == 0 ? originalPercentage.toFixed(0) : originalPercentage;
    return `${percentage}% ${cptModifier?.additional_info}`
  }

  const handleCapture = async ({ target }: any) => {
    if (target.files.length > 0) {
      let newImages = [...images, target.files[0]];
      setImages(newImages);
    }
  };

  const handleDeleteImage = (index, imageId) => {
    setImageIndex(index);
    setImageId(imageId);
    setOpenDeleteImage(true);
  }

  const removeImage = (index) => {
    setMedia(images);
    setImages((media) => media.filter((img, i) => i !== index));
  }

  const handleRenameClick = (index) => {
    setSelectedImageIndex(index);
    setNewImageName(images[index].name);
    setRenameDialogOpen(true);
  };

  const handleRenameClose = () => {
    setRenameDialogOpen(false);
    setNewImageName('');
    setSelectedImageIndex(null);
  };

  const handleRenameSave = () => {
    const updatedImages = [...images];
    const selectedImage = updatedImages[selectedImageIndex];
    const updatedImage = new File([selectedImage], newImageName, {
      type: selectedImage.type,
    });
    updatedImages[selectedImageIndex] = updatedImage;
    setImages(updatedImages);

    handleRenameClose();
  };

  const uploadImages = async (id, procedureId, files, onUploadProgress) => {
    const { status_code: statusCode } = await api.uploadProcedureMedia(
      id,
      procedureId,
      files,
      onUploadProgress
    );
    if (statusCode === api.status.SUCCESS) {
    }
  };

  const updateImages = () => {
    if (media == images || media.length > images.length) {
      getProcedureById();
      setShowEditImages(false);
    } else if (images && images.length > 0) {
      uploadImages(user.id, caseId, images, api.config.onUploadProgress).then(() => {
        getProcedureById();
        setShowEditImages(false);
      });
    }
  }

  const deleteImage = async (index: any, id: any) => {
    const { status_code: statusCode } = await api.deleteImage(
      user.id,
      caseId,
      id
    );
    if (statusCode === api.status.SUCCESS) {
      setImages((media) => media.filter((img, i) => i !== index));
      setOpenDeleteImage(false);
    }
  };

  const displayCustomFieldsCpt = (name, field) => {
    return (
      <Box mb={3}>
        <Paper className={classes.paper}>
          <Typography
            className={[classes.selectlabel, classes.Padding].join(' ')}
            variant="h4"
          >
            {name}
          </Typography>
          <Box>
            <Divider className={classes.divider}></Divider>
          </Box>
          {field && field.length === 0 ? (
            <Typography variant="h3" className={classes.nullData}>
              N/A
            </Typography>
          ) : null}

          <Grid
            item={true}
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={
              field && field.length > 0
                ? classes.cptCodeBox
                : classes.cptCodeBoxWOutmargin
            }
          >
            {field && field.length > 0
              ? field.map((procedureICDItem: any, index) => (
                <Grid
                  item={true}
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  key={procedureICDItem.id + index + ''}
                >
                  <Box
                    className={
                      index % 2 === 0
                        ? classes.cptCodeRight
                        : classes.cptCodeLeft
                    }
                  >
                    <Typography className={classes.code} variant="h6">
                      {procedureICDItem.code}{' '}
                    </Typography>
                    <Typography variant="caption" className={classes.icdBox}>
                      <LinesEllipsis
                        text={procedureICDItem.value}
                        maxLine="3"
                        ellipsis="..."
                        trimRight
                        basedOn="words"
                      />
                    </Typography>
                  </Box>
                </Grid>
              ))
              : null}
          </Grid>
        </Paper>
      </Box>
    );
  };

  return (
    <div>
      <DeleteDialog
        isOpen={openDeleteImage}
        title={'Delete image'}
        firstOption={'Delete'}
        secondOption={'Cancel'}
        handleOptionOne={() => deleteImage(imageIndex, imageId)}
        handleOptionTwo={() => setOpenDeleteImage(false)}
        onClose={() => { }}
      >
        {'Note: Deleting this image is permanent and cannot be undone.'}
      </DeleteDialog>
      <UpgradeDialog
        isOpen={isOpen && !tokenError}
        onClose={() => setisOpen(false)}
        handleChange={() => { }}
        handleCloseDialog={handleCloseDialog}
        userToken={userToken}
        setNewPlan={setNewPlan}
        subscriptionId={currentPlan?.subscr_id}
      />
      <Snackbar open={showError} color="warning">
        <Alert onClose={() => setShowError(false)} severity="warning">
          You can't manage your plan since you have cancelled subscription
        </Alert>
      </Snackbar>
      <Snackbar
        open={showImportCaseMsgReg}
        className={classes.registrySnackbar}
      >
        <Alert
          onClose={() => setShowImportCaseMsgReg(false)}
          color={getSeverity(importCaseMsgReg[0])}
          severity={getSeverity(importCaseMsgReg[0])}
        >
          <Box>
            {importCaseMsgReg[1]}{' '}
            {importCaseMsgReg[0] === 'error' &&
              importCaseMsgReg[1] === 'You need to upgrade your plan' ? (
              <Typography
                className={[classes.inline, classes.cursor].join(' ')}
                variant="h2"
                onClick={() => {
                  if (!tokenError) {
                    setisOpen(true);
                    setShowImportCaseMsgReg(false);
                  } else {
                    setShowError(true);
                    setShowImportCaseMsgReg(false);
                  }
                }}
              >
                Manage plans
              </Typography>
            ) : (
              <Typography
                className={[classes.inline, classes.cursor].join(' ')}
                variant="h2"
                onClick={() => goToRegistry(importCaseMsgReg[2])}
              >
                Open registry
              </Typography>
            )}
          </Box>
        </Alert>
      </Snackbar>{' '}
      <RegistryDialog
        isOpen={openExportCaseDialog}
        title={'Export to registries'}
        filterId={[caseId]}
        setImportCaseMsg={setImportCaseMsgReg}
        setShowImportCaseMsg={setShowImportCaseMsgReg}
        onClose={() => setOpenExportCaseDialog(false)}
      ></RegistryDialog>
      <DeleteDialog
        isOpen={isOpenConfirm}
        title={'Delete Case'}
        firstOption={'Delete'}
        secondOption={'Cancel'}
        handleOptionOne={onDelete}
        handleOptionTwo={handleCloseConfirm}
        onClose={handleCloseConfirm}
      >
        Are you sure you want to delete this case?
      </DeleteDialog>
      <Snackbar open={showMessage} color="error">
        <Alert
          onClose={() => setShowMessage(false)}
          severity={getSeverity(message[0])}
        >
          {message[1]}
        </Alert>
      </Snackbar>
      <Snackbar
        open={props.showEditCaseMessage}
        color={getSeverity(props.caseMessage[0])}
      >
        <Alert
          onClose={() => props.setShowEditCaseMessage(true)}
          severity={getSeverity(message[0])}
        >
          {props.caseMessage[1]}
        </Alert>
      </Snackbar>
      {procedureData !== null ? (
        <Box className={classes.boxPadding}>
          <Box mt={3} ml={1}>
            <>
              {registryId ? (
                <>
                  <NavLink className={classes.link} to="/registries">
                    <Typography variant="h1" className={classes.inline}>
                      {'Registries > '}
                    </Typography>
                  </NavLink>
                  <NavLink
                    className={classes.link}
                    to={'/registry/' + registryId}
                  >
                    <Typography variant="h1" className={classes.inline}>
                      {registryName + ' > '}
                    </Typography>
                  </NavLink>
                </>
              ) : (
                <Box
                  className={[classes.cursor, classes.inline].join(' ')}
                  onClick={() => {
                    if (location.includes('/shared-case/')) {
                      history.push('/shared-dashboard/' + doctorId);
                    } else {
                      history.push('/dashboard');
                    }
                  }}
                >
                  <Typography variant="h1" className={classes.inline}>
                    {'Dashboard > '}
                  </Typography>
                </Box>
              )}
              <Typography
                color="primary"
                variant="body2"
                className={classes.inline}
              >
                {procedureData.patient_first_name}
              </Typography>
            </>
          </Box>
          <Box mt={2} mr={2} color="primary" className={classes.dialogHeader}>
            <Box>
              <Typography className={classes.caseTab} color="primary">
                Case details:{' '}
              </Typography>
              {props.userAccess === 'EDITOR' || location.includes('/case/') ? (
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <>
                      <Button
                        {...bindTrigger(popupState)}
                        className={classes.popup}
                      >
                        <MoreVertIcon onClick={() => { }}></MoreVertIcon>
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        {!location.includes('/shared-case/') ? (
                          <>
                            <MenuItem
                              onClick={() => {
                                handleClickOpen();
                              }}
                            >
                              <OpenInNewIcon
                                className={classes.icon}
                              ></OpenInNewIcon>
                              Export
                              {openCOllapse ? (
                                <ExpandLess className={classes.collapsedIcon} />
                              ) : (
                                <ExpandMore className={classes.collapsedIcon} />
                              )}
                            </MenuItem>
                            <Collapse
                              in={openCOllapse}
                              timeout="auto"
                              unmountOnExit
                              className={classes.collapsed}
                            >
                              <List component="div" disablePadding>
                                <ListItem
                                  button
                                  className={classes.exportPoper}
                                  onClick={() => {
                                    if (registryId) {
                                      pdfRegistryExport();
                                    } else {
                                      pdfExport();
                                    }

                                    popupState.close();
                                  }}
                                >
                                  <PictureAsPdfIcon
                                    className={classes.collapsedItem}
                                  />
                                  Export to PDF file
                                </ListItem>
                                <ListItem
                                  button
                                  className={classes.exportPoper}
                                  onClick={() => {
                                    if (registryId) {
                                      excelRegistryExport();
                                    } else {
                                      excelExport();
                                    }

                                    popupState.close();
                                  }}
                                >
                                  <DescriptionIcon
                                    className={classes.collapsedItem}
                                  />
                                  Export to Excel file
                                </ListItem>
                                {!registryId ? (
                                  <ListItem
                                    button
                                    className={classes.exportPoper}
                                    onClick={() => {
                                      setOpenExportCase(false);
                                      setOpenExportCaseDialog(true);
                                    }}
                                  >
                                    <FolderIcon
                                      className={classes.collapsedItem}
                                    />
                                    Export to Registries
                                  </ListItem>
                                ) : null}
                              </List>
                            </Collapse>
                          </>
                        ) : null}
                        <MenuItem
                          onClick={() => {
                            popupState.close();
                            if (registryId) {
                              editRegistryCase(caseId, registryId);
                            } else {
                              if (location.includes('/shared-case/')) {
                                editSharedCase(caseId, doctorId);
                              } else {
                                editCase(caseId);
                              }
                            }
                          }}
                        >
                          <EditIcon />
                          Edit
                        </MenuItem>
                        {!location.includes('/shared-case/') ? (
                          <MenuItem
                            onClick={() => {
                              popupState.close();
                              setIsOpenConfirm(true);
                            }}
                          >
                            <DeleteIcon />
                            Delete
                          </MenuItem>
                        ) : null}
                      </Menu>
                    </>
                  )}
                </PopupState>
              ) : null}
            </Box>
          </Box>

          <Dialog open={caseIndicator} onClose={handleClose}>
            <CircularProgress className={classes.indicator} />
          </Dialog>
          <Dialog open={openImage}>
            <Box className={classes.prevwiedImage}>
              <Box className={classes.dialogTitle}>
                <Box></Box>
                <ClearIcon
                  onClick={handleImageClose}
                  className={classes.cursor}
                ></ClearIcon>
              </Box>
              <img
                alt="caseImg"
                className={classes.previewImage}
                src={previewImage}
              ></img>
            </Box>
          </Dialog>
          <Dialog open={open} onClose={handleDialogClose}>
            <DialogContent>
              <DialogContentText>
                <Box className={classes.dialogTitle}>
                  <Typography variant="h3">Export files</Typography>
                  <ClearIcon
                    onClick={handleDialogClose}
                    className={classes.cursor}
                  ></ClearIcon>
                </Box>
                <Box>Are you sure you want to export this procedure?</Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  if (registryId) {
                    excelRegistryExport();
                  } else {
                    excelExport();
                  }
                }}
                color="primary"
              >
                Export as Excel
              </Button>
              <Button
                onClick={() => {
                  if (registryId) {
                    pdfRegistryExport();
                  } else {
                    pdfExport();
                  }
                }}
                color="primary"
                autoFocus
              >
                Export as PDF
              </Button>
            </DialogActions>
          </Dialog>
          <Box mb={3}>
            <Paper className={classes.paper}>
              <Grid item={true} container spacing={0}>
                <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    className={[classes.header, classes.Padding].join(' ')}
                    variant="h4"
                  >
                    Patient info
                  </Typography>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid
                  item={true}
                  xs={12}
                  sm={6}
                  className={classes.dividerMobile}
                >
                  <Divider
                    className={[classes.divider, classes.dividerMobile].join(
                      ' '
                    )}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                  <Typography
                    className={[classes.label, classes.Padding].join(' ')}
                    variant="h4"
                  >
                    Patient Name
                  </Typography>
                  <Typography
                    variant="h3"
                    className={[classes.Padding, classes.fontSize].join(' ')}
                  >
                    {procedureData.patient_last_name
                      ? procedureData.patient_last_name +
                      ', ' +
                      procedureData.patient_first_name
                      : 'N/A'}
                  </Typography>
                  <Divider />
                </Grid>

                <Grid item={true} xs={12} sm={6} md={6}>
                  <Typography
                    className={[classes.label, classes.Padding].join(' ')}
                    variant="h4"
                  >
                    Gender
                  </Typography>
                  <Typography
                    variant="h3"
                    className={[classes.Padding, classes.fontSize].join(' ')}
                  >
                    {procedureData.gender ? procedureData.gender.charAt(0).toUpperCase() + procedureData.gender.substring(1).toLowerCase() : 'N/A'}
                  </Typography>
                  <Divider />
                </Grid>

                <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                  <Typography
                    className={[classes.label, classes.Padding].join(' ')}
                    variant="h4"
                  >
                    Date of Birth
                  </Typography>
                  <Typography
                    variant="h3"
                    className={[classes.Padding, classes.fontSize].join(' ')}
                  >
                    {procedureData.date_of_birth
                      ? procedureData.date_of_birth
                      : 'N/A'}
                  </Typography>
                  <Divider />
                </Grid>

                {!location.includes('/registrycase/') ||
                  registryFields.indexOf('age') !== -1 ? (
                  <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                    <Typography
                      className={[classes.label, classes.Padding].join(' ')}
                      variant="h4"
                    >
                      Age
                    </Typography>
                    <Typography
                      variant="h3"
                      className={[classes.Padding, classes.fontSize].join(' ')}
                    >
                      {procedureData.age ? procedureData.age : 'N/A'}
                    </Typography>
                    <Divider />
                  </Grid>
                ) : null}

                {!location.includes('/registrycase/') ||
                  registryFields.indexOf('mrn') !== -1 ? (
                  <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                    <Typography
                      className={[classes.label, classes.Padding].join(' ')}
                      variant="h4"
                    >
                      Medical Record Number
                    </Typography>
                    <Typography
                      variant="h3"
                      className={[classes.Padding, classes.fontSize].join(' ')}
                    >
                      {procedureData.mrn ? procedureData.mrn : 'N/A'}
                    </Typography>
                    <Divider />
                  </Grid>
                ) : null}

                {!location.includes('/registrycase/') || registryFields.indexOf('race') !== -1 ? (
                  <Grid item={true} xs={12} sm={6} md={6}>
                    <Typography
                      className={[classes.label, classes.Padding].join(' ')}
                      variant="h4"
                    >
                      Race
                    </Typography>
                    <Typography
                      variant="h3"
                      className={[classes.Padding, classes.fontSize].join(' ')}
                    >
                      {procedureData.race ? procedureData.race : 'N/A'}
                    </Typography>
                    <Divider />
                  </Grid>
                ) : null}

                {!location.includes('/registrycase/') ||
                  registryFields.indexOf('insurance') !== -1 ? (
                  <>
                    <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Insurance
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(
                          ' '
                        )}
                      >
                        {procedureData.insurance
                          ? procedureData.insurance
                          : 'N/A'}
                      </Typography>
                      {registryId ? <Divider /> : null}
                      <Divider />
                    </Grid>
                  </>
                ) : null}

                <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                  <Typography
                    className={[classes.label, classes.Padding].join(' ')}
                    variant="h4"
                  >
                    Institution
                  </Typography>
                  <Typography
                    variant="h3"
                    className={[classes.Padding, classes.fontSize].join(' ')}
                  >
                    {hospitalData ? hospitalData?.name : 'N/A'}
                  </Typography>
                  <Divider />
                </Grid>

                {!location.includes('/registrycase/') ||
                  registryFields.indexOf('primary_surgeon') !== -1 ? (
                  <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                    <Typography
                      className={[classes.label, classes.Padding].join(' ')}
                      variant="h4"
                    >
                      Primary surgeon (If not you)
                    </Typography>
                    <Typography
                      variant="h3"
                      className={[classes.Padding, classes.fontSize].join(' ')}
                    >
                      {procedureData.primary_surgeon ? procedureData.primary_surgeon : 'N/A'}
                    </Typography>
                    <Divider />
                  </Grid>
                ) : null}

              </Grid>
            </Paper>
          </Box>

          <Box mb={3}>
            <Box>
              <Paper className={classes.paper}>
                <Grid item={true} container spacing={0}>
                  <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      className={[classes.header, classes.Padding].join(' ')}
                      variant="h4"
                    >
                      Diagnosis
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                  <Typography variant="h3" className={classes.nullData}>
                    {procedureData.procedure_diagnosis ? procedureData.procedure_diagnosis : 'N/A'}
                  </Typography>
                </Grid>
              </Paper>

              <Grid item={true} xs={12} sm={12} md={12}></Grid>
            </Box>
          </Box>

          {!location.includes('/registrycase/') ||
            registryFields.indexOf('diagnosisCodes') !== -1 ? (
            <Box mb={3}>
              <Paper className={classes.paper}>
                <Typography
                  className={[classes.selectlabel, classes.Padding].join(' ')}
                  variant="h4"
                >
                  ICD-9/10
                </Typography>
                <Box>
                  <Divider className={classes.divider}></Divider>
                </Box>
                {procedureICDData && procedureICDData.length === 0 ? (
                  <Typography variant="h3" className={classes.nullData}>
                    N/A
                  </Typography>
                ) : null}

                <Grid
                  item={true}
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={
                    procedureICDData && procedureICDData.length > 0
                      ? classes.cptCodeBox
                      : classes.cptCodeBoxWOutmargin
                  }
                >
                  {procedureICDData && procedureICDData.length > 0
                    ? procedureICDData.map((procedureICDItem: any, index) => (
                      <Grid
                        item={true}
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        key={
                          registryId
                            ? procedureICDItem.id + index + ''
                            : procedureICDItem.icd_lookup.id + index + ''
                        }
                      >
                        <Box
                          className={
                            index % 2 === 0
                              ? classes.cptCodeRight
                              : classes.cptCodeLeft
                          }
                        >
                          <Typography className={classes.code} variant="h6">
                            {registryId
                              ? procedureICDItem.code
                              : procedureICDItem.icd_lookup.code}{' '}
                          </Typography>
                          <Typography
                            variant="caption"
                            className={classes.icdBox}
                          >
                            <LinesEllipsis
                              text={
                                registryId
                                  ? procedureICDItem.value
                                  : procedureICDItem.icd_lookup.value
                              }
                              maxLine="3"
                              ellipsis="..."
                              trimRight
                              basedOn="words"
                            />
                          </Typography>
                        </Box>
                      </Grid>
                    ))
                    : null}
                </Grid>
              </Paper>
            </Box>
          ) : null}
          {!location.includes('/registrycase/') ||
            registryFields.indexOf('cptCodes') !== -1 ? (
            <Box mb={3}>
              <Paper className={classes.paper}>
                <Typography
                  className={[classes.selectlabel, classes.Padding].join(' ')}
                  variant="h4"
                >
                  CPT Codes and RVUs
                </Typography>
                <Box>
                  <Divider className={classes.divider}></Divider>
                </Box>
                <Grid
                  item={true}
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={
                    procedureCPTData && procedureCPTData.length > 0
                      ? classes.cptCodeBox
                      : classes.cptCodeBoxWOutmargin
                  }
                >
                  {sumOfRvu > 0 && registryId === undefined ? (
                    <Grid item={true} xs={12} className={classes.rvuContainer}>
                      <Box className={classes.rvuRow}>
                        <Typography variant="subtitle1">
                          Total RVUs
                        </Typography>
                        <Typography variant="subtitle1">{sumOfRvu.toFixed(3)}</Typography>
                      </Box>
                      {procedureCPTData.length > 1 ? (
                        <Box>
                          <Divider />
                          <Box className={classes.rvuRow}>
                            <Typography variant="subtitle1">
                              Total Reimbursable RVUs
                            </Typography>
                            <Typography variant="subtitle1">{totalReimbursable.toFixed(3)}</Typography>
                          </Box>
                        </Box>) : null}
                    </Grid>
                  ) : null}{' '}
                  {procedureCPTData && procedureCPTData.length > 0 ? (
                    procedureCPTData.map((procedureCPTItem: any, index) => (
                      <Grid
                        item={true}
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        key={
                          registryId
                            ? procedureCPTItem.id + index + ''
                            : procedureCPTItem.cpt_lookup.id + index + ''
                        }
                      >
                        <Box
                          className={
                            index % 2 === 0
                              ? classes.cptCodeRight
                              : classes.cptCodeLeft
                          }
                        >
                          <Typography className={classes.code} variant="h6">
                            {registryId
                              ? procedureCPTItem.code
                              : procedureCPTItem.cpt_lookup.code}{' '}
                          </Typography>
                          <Typography
                            variant="caption"
                            className={classes.icdBox}
                          >
                            <LinesEllipsis
                              text={
                                registryId
                                  ? procedureCPTItem.value
                                  : procedureCPTItem.cpt_lookup.value
                              }
                              maxLine="3"
                              ellipsis="..."
                              trimRight
                              basedOn="words"
                            />
                          </Typography>
                        </Box>
                      </Grid>
                    ))
                  ) : (
                    <Typography variant="h3" className={classes.nullData}>
                      N/A
                    </Typography>
                  )}
                </Grid>
              </Paper>
            </Box>
          ) : null}

          {(!location.includes('/registrycase/') ||
            registryFields.indexOf('cptModifiers') !== -1) &&
            procedureCPTData.length > 0 ? (
            <Box mb={3}>
              <Paper className={classes.paper}>
                <Typography
                  className={[classes.selectlabel, classes.Padding].join(' ')}
                  variant="h4"
                >
                  CPT Modifiers
                </Typography>
                <Box>
                  <Divider className={classes.divider}></Divider>
                </Box>
                <Grid
                  item={true}
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.cptCodeBox}
                >
                  {cptModifier && procedureCPTData.length > 0 ? (
                    <Grid item={true} xs={12} className={classes.rvuContainer}>
                      <Box className={classes.rvuRow}>
                        <Typography variant="subtitle1">
                          Total Reimbursable RVUs After CPT Modifier
                        </Typography>
                        <Typography variant="subtitle1">{(totalReimbursable * (cptModifier.percentage / 100)).toFixed(3)}</Typography>
                      </Box>
                    </Grid>
                  ) : null}{' '}
                  {cptModifier ? (
                    [cptModifier].map((modifier: any, index) => (
                      <Grid
                        item={true}
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                      >
                        <Box
                          className={classes.cptCodeRight}
                        >
                          <Typography className={classes.code} variant="h6">
                            {`Modifier: ${cptModifier.id}`}{' '}
                          </Typography>
                          <Typography
                            variant="caption"
                            className={classes.icdBox}
                          >
                            <LinesEllipsis
                              text={`Description: ${cptModifier.description}`}
                              maxLine="5"
                              ellipsis="..."
                              trimRight
                              basedOn="words"
                            />
                            <Typography className={classes.code} variant="h6">
                              {`Percentage: ${getCPTModifierPercentage()}`}{' '}
                            </Typography>
                          </Typography>
                        </Box>
                      </Grid>
                    ))
                  ) : (
                    <Typography variant="h3" className={classes.nullData}>
                      N/A
                    </Typography>
                  )}
                </Grid>
              </Paper>
            </Box>
          ) : null}

          <Box mb={3}>
            <Box>
              <Paper className={classes.paper}>
                <Grid item={true} container spacing={0}>
                  <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      className={[classes.header, classes.Padding].join(' ')}
                      variant="h4"
                    >
                      Procedures details
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                    <Divider className={classes.divider} />
                  </Grid>

                  {isNeurosurgery() || isInterventional() || isRadiology() ?
                    (<Grid item={true} xs={12} sm={6} md={6} lg={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Date of Procedure
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.date_of_procedure ? procedureData.date_of_procedure : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>) : null}

                  {(!location.includes('/registrycase/') || registryFields.indexOf('procedure_name') !== -1) &&
                    isNeurosurgery() || isInterventional() || isRadiology() ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Procedure name
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.procedure_name ? procedureData.procedure_name : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  <Grid item={true} xs={6} sm={6} md={6} lg={6}>
                    <Typography
                      className={[classes.label, classes.Padding].join(' ')}
                      variant="h4"
                    >
                      Procedure Type
                    </Typography>
                    <Typography
                      variant="h3"
                      className={[classes.Padding, classes.fontSize].join(' ')}
                    >
                      {procedureData.type ? procedureData.type : 'N/A'}
                    </Typography>
                    <Divider />
                  </Grid>

                  {!location.includes('/registrycase/') &&
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' && procedureData.type == 'Cranial' ||
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' && procedureData.type == 'Spine' ||
                    registryFields.indexOf('approach') !== -1 &&
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' && procedureData.type == 'Cranial' ||
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' && procedureData.type == 'Spine' ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Approach
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.approach ? procedureData.approach : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {(!location.includes('/registrycase/') || registryFields.indexOf('indication') !== -1) &&
                    isNeurosurgery() && (isBedside() || isPain() || isRadiosurgery()) ||
                    isNeurosurgery() && procedureData.type !== '' && procedureData.type !== 'Other' ||
                    isInterventional() || isRadiology() ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Indication
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.indication ? procedureData.indication : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {(!location.includes('/registrycase/') || registryFields.indexOf('minimally_invasive') !== -1) &&
                    isNeurosurgery() && isSpine() ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Minimally invasive
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.minimally_invasive ? procedureData.minimally_invasive : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {(!location.includes('/registrycase/') || registryFields.indexOf('navigation') !== -1) &&
                    isNeurosurgery() && isSpine() ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Navigation
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.navigation ? procedureData.navigation : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {(!location.includes('/registrycase/') || registryFields.indexOf('robotic') !== -1) &&
                    isNeurosurgery() && isSpine() ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Robotic
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.robotic ? procedureData.robotic : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {(!location.includes('/registrycase/') || registryFields.indexOf('side') !== -1) &&
                    isNeurosurgery() || isInterventional() ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Side
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.side ? procedureData.side : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {(!location.includes('/registrycase/') || registryFields.indexOf('location') !== -1) &&
                    showLocation() ?
                    (<Grid item={true} xs={12} sm={6} md={6} lg={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Location
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.location ? procedureData.location : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>) : null}

                  {(!location.includes('/registrycase/') || registryFields.indexOf('_procedure') !== -1) &&
                    ((isNeurosurgery() && isBedside() ||
                      isNeurosurgery() && isInter()) &&
                      !isInterventional()) ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Procedure
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData._procedure ? procedureData._procedure : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {(!location.includes('/registrycase/') ||
                    registryFields.indexOf('tici_grade') !== -1) &&
                    (isNeurosurgery() && isInter() && isThrombectomy()) ||
                      isInterventional() && isThrombectomy() ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        TICI grade
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.tici_grade ? procedureData.tici_grade : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {!location.includes('/registrycase/') &&
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' && procedureData.type === 'Spine' ||
                    registryFields.indexOf('osteotomy') !== -1 &&
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' && procedureData.type === 'Spine' ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Osteotomy
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.osteotomy ? procedureData.osteotomy : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {!location.includes('/registrycase/') &&
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                    procedureData.type === 'Spine' ||
                    registryFields.indexOf('_fusion') !== -1 &&
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                    procedureData.type === 'Spine' ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Fusion
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData._fusion ? procedureData._fusion : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}


                  {!location.includes('/registrycase/') &&
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                    procedureData.type === 'Spine' ||
                    registryFields.indexOf('interbody_fusion') !== -1 &&
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                    procedureData.type === 'Spine' ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Interbody fusion
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.interbody_fusion ? procedureData.interbody_fusion : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {!location.includes('/registrycase/') &&
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                    procedureData.type === 'Spine' ||
                    registryFields.indexOf('extension_to_pelvis') !== -1 &&
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                    procedureData.type === 'Spine' ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Extension to pelvis
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.extension_to_pelvis ? procedureData.extension_to_pelvis : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {!location.includes('/registrycase/') && isNeurosurgery() && isSpine() ||
                    registryFields.indexOf('fusion_levels') !== -1 && isNeurosurgery() && isSpine() ? (
                    <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Fusion levels
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.fusion_levels ? procedureData.fusion_levels : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {!location.includes('/registrycase/') && isNeurosurgery() && isSpine() ||
                    registryFields.indexOf('decompression_levels') !== -1 && isNeurosurgery() && isSpine() ? (
                    <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Decompression levels
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.decompression_levels ? procedureData.decompression_levels : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {!location.includes('/registrycase/') &&
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                    procedureData.type === 'Spine' ||
                    registryFields.indexOf('morphogenic_protein') !== -1 &&
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                    procedureData.type === 'Spine' ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Morphogenic protein
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.morphogenic_protein ? procedureData.morphogenic_protein : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {(!location.includes('/registrycase/') || registryFields.indexOf('implant') !== -1) &&
                    ((isNeurosurgery() && !isSpine() && !isEmptyIndication() && !isOtherProcedure()) ||
                      isInterventional() || isRadiology()) ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Implant
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.implant ? procedureData.implant : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {(!location.includes('/registrycase/') || registryFields.indexOf('implant_type') !== -1) &&
                    ((isNeurosurgery() && !isSpine() && !isEmptyIndication() && !isOtherProcedure()) ||
                      isInterventional() || isRadiology()) ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Implant type
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.implant_type ? procedureData.implant_type : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {!location.includes('/registrycase/') &&
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' && procedureData.type !== '' && procedureData.type !== 'Other' ||
                    user.user_profile[0].speciality?.name == 'Interventional Neurology/Neuroradiology' ||
                    !user.user_profile[0].speciality || user.user_profile[0].speciality?.name ||
                    registryFields.indexOf('vendors') !== -1 &&
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' && procedureData.type !== '' && procedureData.type !== 'Other' ||
                    user.user_profile[0].speciality?.name == 'Interventional Neurology/Neuroradiology' ||
                    !user.user_profile[0].speciality || user.user_profile[0].speciality?.name ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Vendors
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.vendors ? procedureData.vendors : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {(!location.includes('/registrycase/') || registryFields.indexOf('access') !== -1) &&
                    (isInterventional() || isRadiology()) ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Access
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.access
                          ? procedureData.access
                          : 'N/A'}
                      </Typography>
                      {registryId ? <Divider /> : null}
                      <Divider />
                    </Grid>
                  ) : null}

                  {(!location.includes('/registrycase/') || registryFields.indexOf('vascular_closure_device') !== -1) &&
                    (isInterventional() || isRadiology()) ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Vascular closure device (if used)
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.vascular_closure_device
                          ? procedureData.vascular_closure_device
                          : 'N/A'}
                      </Typography>
                      {registryId ? <Divider /> : null}
                      <Divider />
                    </Grid>
                  ) : null}

                  {(!location.includes('/registrycase/') || registryFields.indexOf('duration_of_radiation') !== -1) &&
                    (isNeurosurgery() && !isEmptyProcedure() && isInter() ||
                      isInterventional() || isRadiology()) ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Duration of radiation
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.duration_of_radiation ? procedureData.duration_of_radiation : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {!location.includes('/registrycase/') &&
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' ||
                    !location.includes('/registrycase/') &&
                    user.user_profile[0].speciality?.name == 'Interventional Neurology/Neuroradiology' ||
                    registryFields.indexOf('duration_of_procedure') !== -1 &&
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' ||
                    registryFields.indexOf('duration_of_procedure') !== -1 &&
                    user.user_profile[0].speciality?.name == 'Interventional Neurology/Neuroradiology' ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Duration of procedure
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.duration_of_procedure ? procedureData.duration_of_procedure : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {!location.includes('/registrycase/') &&
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                    procedureData.type === 'Functional' ||
                    registryFields.indexOf('target') !== -1 &&
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                    procedureData.type === 'Functional' ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Target
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.target ? procedureData.target : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {!location.includes('/registrycase/') &&
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                    procedureData.type === 'Peripheral nerves' ||
                    registryFields.indexOf('nerve') !== -1 &&
                    user.user_profile[0].speciality?.name == 'Neurological Surgery' &&
                    procedureData.type === 'Peripheral nerves' ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Nerve
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.nerve ? procedureData.nerve : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {!isNeurosurgery() && !isInterventional() && !isRadiology() ?
                    (<Grid item={true} xs={12} sm={6} md={6} lg={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Date of Procedure
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.date ? procedureData.date : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>) : null}

                  {(!location.includes('/registrycase/') || registryFields.indexOf('findings') !== -1) &&
                    !isRadiology() ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Findings
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.findings ? procedureData.findings : 'N/A'}
                      </Typography>
                      <Divider />
                    </Grid>
                  ) : null}

                  {(!location.includes('/registrycase/') || registryFields.indexOf('complications') !== -1) &&
                    !isRadiology() ? (
                    <Grid item={true} xs={12} sm={6} md={6}>
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Complications
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.complications
                          ? procedureData.complications
                          : 'N/A'}
                      </Typography>
                      {registryId ? <Divider /> : null}
                      <Divider />
                    </Grid>
                  ) : null}

                  {(!location.includes('/registrycase/') || registryFields.indexOf('outcome') !== -1) &&
                    !isRadiology() ? (
                    <Grid
                      item={true}
                      xs={12}
                      sm={6}
                      md={6}
                      className={classes.procedureDataFieldLast}
                    >
                      <Typography
                        className={[classes.label, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Outcome
                      </Typography>
                      <Typography
                        variant="h3"
                        className={[classes.Padding, classes.fontSize].join(' ')}
                      >
                        {procedureData.outcome ? procedureData.outcome : 'N/A'}
                      </Typography>
                      {registryId ? <Divider /> : null}
                      <Divider />
                    </Grid>
                  ) : null}
                </Grid>
              </Paper>
              {caseProcedures.map((procedure, index) => {
                return <CaseProcedureDetails caseId={caseId} key={index} index={index} procedure={procedure} />
              })}
              <Grid item={true} xs={12} sm={12} md={12}></Grid>
            </Box>
            {customizedField && customizedField.length > 0 ? (
              <Box my={3}>
                <Paper className={classes.paper}>
                  <Grid item={true} container spacing={0}>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                      <Typography
                        className={[classes.header, classes.Padding].join(' ')}
                        variant="h4"
                      >
                        Custom fields
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                      <Divider className={classes.divider} />
                    </Grid>

                    {normalFields.map((customField: any, index) => (
                      <Fragment key={customField[index] + index + ''}>
                        <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                          <Typography
                            className={[classes.label, classes.Padding].join(
                              ' '
                            )}
                            variant="h4"
                          >
                            {customField}
                          </Typography>
                          <Typography
                            variant="h3"
                            className={[classes.Padding, classes.fontSize].join(
                              ' '
                            )}
                          >
                            {customizedData[customField]}
                          </Typography>
                          <Divider />
                        </Grid>
                      </Fragment>
                    ))}
                  </Grid>
                </Paper>
              </Box>
            ) : null}
            {cptTitle && cptTitle.length > 0
              ? cptTitle.map((customField: any, index) =>
                displayCustomFieldsCpt(
                  customField,
                  customizedData[customField]
                )
              )
              : null}

            {(!location.includes('/registrycase/') ||
              registryFields.indexOf('notes') !== -1) && !showEditNotes ? (
              <Paper className={classes.notePaper}>
                <Box className={classes.noteTitle}>
                  <Typography
                    className={[
                      classes.label,
                      classes.noteMobilePadding,
                      classes.fontSize
                    ].join(' ')}
                    variant="h4"
                  >
                    Notes
                  </Typography>
                  <Box >
                    <EditIcon
                      onClick={() => setShowEditNotes(true)}
                      color="secondary"
                      className={classes.cursor}
                      style={{ fontSize: 18 }}
                    ></EditIcon>
                  </Box>
                </Box>
                <Divider />
                <Grid item={true} container className={classes.notes} lg={12}>
                  {procedureData.notes && procedureData.notes.length > 0
                    ? procedureData.notes.map((note: any, index) => (
                      <Fragment key={note.id + index + ''}>
                        <Grid
                          item={true}
                          className={
                            index % 2 === 0
                              ? classes.noteBoxLeft
                              : classes.noteBoxRight
                          }
                          lg={6}
                          xs={12}
                        >
                          <Grid item={true} className={classes.note}>
                            <Typography variant="h4">Note</Typography>
                            <Typography
                              className={classes.inline}
                              variant="body1"
                            >
                              {' '}
                              <Typography variant="caption">
                                {note.date}
                              </Typography>
                            </Typography>
                          </Grid>

                          <Typography
                            className={[
                              classes.noteData,
                              classes.fontSize
                            ].join(' ')}
                            variant="h3"
                            noWrap={!isExpanded[index]}
                          >
                            {note?.note}
                          </Typography>

                          <Box className={classes.seeMoreBox}>
                            <Button className={classes.seeMoreBtn} onClick={() => toggleExpand(index)}>{isExpanded[index] ? 'See less' : 'See more'}</Button>
                          </Box>

                        </Grid>
                        {(index + 1) % 2 === 0 &&
                          index !== 0 &&
                          index + 1 < procedureData.notes.length ? (
                          <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                            <Divider className={classes.divider} />
                          </Grid>
                        ) : null}
                        {index - 1 < procedureData.notes.length ? (
                          <Grid
                            item={true}
                            xs={12}
                            sm={6}
                            className={classes.dividerMobile}
                          >
                            <Divider
                              className={[
                                classes.divider,
                                classes.dividerMobile
                              ].join(' ')}
                            />
                          </Grid>
                        ) : null}
                      </Fragment>
                    ))
                    : null}
                </Grid>
                {procedureData.notes &&
                  procedureData.notes.length > 0 ? null : (
                  <Typography variant="body1" className={classes.nullData}>
                    {'No added notes.'}
                  </Typography>
                )}
              </Paper>
            ) : null}

            {(!location.includes('/registrycase/') ||
              registryFields.indexOf('notes') !== -1) && showEditNotes ? (
              <Paper className={classes.notePaper}>
                <Box className={classes.noteTitle}>
                  <Typography
                    className={[
                      classes.label,
                      classes.noteMobilePadding,
                      classes.fontSize
                    ].join(' ')}
                    variant="h4"
                  >
                    Notes
                  </Typography>
                  <Box ml={'20px'} style={{display: 'flex', alignItems: 'center'}}>
                    <AddIcon
                      onClick={() => setShowAddNoteText(true)}
                      color="secondary"
                      className={classes.cursor}
                    ></AddIcon>
                  </Box>
                </Box>
                {notes && notes.length > 0 || fields && fields.length > 0 ? (
                  <Box mt={1} mb={2}>
                    <Divider />
                  </Box>
                ) : null}
                <Grid
                  container
                  item={true}
                  xs={12}
                  className={classes.notesContainer}
                >
                  {fields.map((item, index) => {
                    return (
                      <Grid
                        item={true}
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        key={item.id}
                        className={classes.noteFieldEdited}
                      >
                        <TextField
                          name={`notes[${index}].note`}
                          defaultValue={`${item.note}`}
                          label="Note"
                          inputRef={register({ required: true })}
                          variant="outlined"
                          fullWidth
                          rowsMax={3}
                          multiline
                          rows={3}
                          onChange={(e) => handleNoteChange(e.target.value, index)}
                        />

                        <DeleteOutlineIcon
                          onClick={() => remove(index)}
                          className={classes.cursor}
                          color="error"
                        ></DeleteOutlineIcon>
                      </Grid>
                    );
                  })}
                  {showAddNoteText ? (
                    <Grid
                      item={true}
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      className={classes.noteFieldEdited}
                    >
                      <TextField
                        variant="outlined"
                        name="note"
                        value={editNoteField}
                        onChange={(e) => {
                          setEditNoteField(e.target.value);
                        }}
                        label="Note"
                        margin="normal"
                        fullWidth
                        rowsMax={3}
                        multiline
                        rows={3}
                      />
                      <Button
                        onClick={() => {
                          if (editNoteField.length) {
                            append({ note: editNoteField, id: 0 });
                          }

                          setEditNoteField('');
                          setShowAddNoteText(false);
                          setNotesHasChanged(true);
                        }}
                        color="secondary"
                        className={classes.cursor}
                      >Done</Button>
                    </Grid>
                  ) : null}
                </Grid>
                {fields.length > 0 ? (<Box className={classes.saveBtnBox}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={
                      notesHasChanged ? classes.saveButtonEdited : classes.saveButton
                    }
                    onClick={updateNotes}
                  >
                    Save
                  </Button>
                </Box>) : null}
              </Paper>
            ) : null}
          </Box>

          {(!location.includes('/registrycase/') ||
            registryFields.indexOf('images') !== -1) &&
            currentPlan?.plan?.name !== 'Free' && !showEditImages ? (
            <Box mb={3}>
              <Paper className={classes.paper}>
                <Box className={classes.noteTitle}>
                  <Typography
                    className={[
                      classes.label,
                      classes.noteMobilePadding,
                      classes.fontSize
                    ].join(' ')}
                    variant="h4"
                  >
                    Images
                  </Typography>
                  <Box >
                    <EditIcon
                      onClick={() => {
                        setShowEditImages(true);
                        getProcedureImages();
                      }}
                      color="secondary"
                      className={classes.cursor}
                      style={{ fontSize: 18 }}
                    ></EditIcon>
                  </Box>
                </Box>
                <Divider />
                <Grid
                  item={true}
                  className={
                    media !== undefined && media.length > 0
                      ? classes.imageContainer
                      : ''
                  }
                  xs={12}
                  sm={12}
                  md={12}
                  container
                  spacing={1}
                >
                  {media !== undefined && media[0] !== null
                    ? media.map((item: any, index) =>
                      media[index].deleted_flag ? null : (
                        <Grid
                          item={true}
                          xs={6}
                          sm={4}
                          md={3}
                          lg={4}
                          xl={2}
                          key={item.id + index + ''}
                        >
                          <Box className={classes.imgBox} mx={0.5} my={0.5}>
                            <Box className={classes.image}>
                              <img
                                alt="caseImg"
                                onClick={() =>
                                  previewImageDialog(item.urls.href_original)
                                }
                                className={classes.caseImage}
                                src={item.urls ? item.urls.href_original : ''}
                              />
                            </Box>
                            <Box className={classes.hrContainer}>
                              <Divider className={classes.divider} />
                            </Box>

                            <Box className={classes.imageFooter}>
                              <Typography
                                noWrap
                                variant="overline"
                                align="left"
                              >
                                {media[index].image_name.length > 20
                                  ? media[index].image_name.substring(0, 20) +
                                  '...'
                                  : media[index].image_name}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      )
                    )
                    : null}
                </Grid>
                {media && media.length > 0 ? null : (
                  <Typography variant="body1" className={classes.nullImageData}>
                    {'No added images.'}
                  </Typography>
                )}
              </Paper>
            </Box>
          ) : null}

          {(!location.includes('/registrycase/') ||
            registryFields.indexOf('images') !== -1) &&
            currentPlan?.plan?.name !== 'Free' && showEditImages ? (<Box mb={2}>
              <Paper>
                <Box >
                  <Box className={classes.titleImage}>
                    <Typography
                      className={[
                        classes.label,
                        classes.fontSize
                      ].join(' ')}
                      variant="h4"
                    >
                      Images
                    </Typography>
                    <label htmlFor="raised-button-file">
                      <AddIcon
                        color="secondary"
                        className={[classes.cursor, classes.addIcon].join(' ')}
                      ></AddIcon>
                    </label>
                    <input
                      onChange={handleCapture}
                      id="raised-button-file"
                      type="file"
                      hidden
                      onClick={(event) => (event.currentTarget.value = '')}
                      accept="image/*"
                    />
                  </Box>
                  {caseId && images.length > 0 ? (
                    <Box mt={2} className={classes.imageDivider}>
                      <Divider />
                    </Box>
                  ) : null}
                  <Grid
                    item={true}
                    className={classes.imageContainer}
                    xs={12}
                    sm={12}
                    md={12}
                    container
                  >
                    {images.length > 0
                      ? images.map((image, index) => (
                        <Grid
                          item={true}
                          xs={6}
                          sm={4}
                          md={3}
                          lg={4}
                          xl={2}
                          key={Image + '' + index}
                        >
                          <Box className={classes.imgBox} mx={2} my={0.5}>
                            <Box className={classes.image}>
                              <img
                                className={classes.caseImage}
                                src={image.urls ? image.urls.href_small : URL.createObjectURL(image)}
                                alt={image.urls ? image.urls.href_small : URL.createObjectURL(image)}
                                onClick={() => setOpenedImage(image)}
                              />
                            </Box>
                            <Box className={classes.imageFooter}>
                              <Divider className={classes.divider} />
                              <Box className={classes.imageName}>
                                <Typography noWrap variant="overline" align="left">
                                  {image.image_name ? image.image_name : image.name}
                                </Typography>
                                <DeleteOutlineIcon
                                  onClick={() => image.id ? handleDeleteImage(index, image.id) : removeImage(index)}
                                  className={classes.cursor}
                                  color="error"
                                ></DeleteOutlineIcon>
                                {!image.id ? (<Button
                                  onClick={() => handleRenameClick(index)}
                                  color="secondary"
                                  className={classes.cursor}
                                >
                                  Rename
                                </Button>) : null}
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      ))
                      : null}
                    {/* Code to display the selected image */}
                    {openedImage && (
                      <Dialog open={true} onClose={() => setOpenedImage(null)}>
                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: 5 }}>
                          <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setOpenedImage(null)}
                            aria-label="close"
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                        <img
                          className={classes.openedImage}
                          src={openedImage.urls.href_original}
                          alt={openedImage.urls.href_original}
                        />
                      </Dialog>
                    )}
                  </Grid>
                </Box>
                {media != images && images && images.length > 0 ? (<Box className={classes.saveBtnBox}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={
                      media != images ? classes.saveButtonEdited : classes.saveButton
                    }
                    onClick={updateImages}
                  >
                    Save
                  </Button>
                </Box>) : null}
              </Paper>

              {/* Rename Dialog */}
              <Dialog open={renameDialogOpen} onClose={handleRenameClose}>
                <DialogTitle>Rename Image</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="New Image Name"
                    fullWidth
                    value={newImageName}
                    onChange={(e) => setNewImageName(e.target.value)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleRenameClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleRenameSave} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>) : null}
        </Box>
      ) : null
      }
    </div >
  );
};

export default withWidth()(CaseDetails);
